import React from "react";
import CompanyStyle from "../../assets/style/ecomerce/company.js";
// import customize from "../../assets/image/ecomerce/company/customize.png"
import wix from "../../assets/image/ecomerce/company/wix.svg"
import woo from "../../assets/image/ecomerce/company/woo.svg"
import shopware from "../../assets/image/ecomerce/company/shopware.svg"
const Company = () => {
  return (
    <>
      <CompanyStyle />
      <div className="">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          {/*  */}
          <div className="main_websitename_div">
            <div className="second_websitename_div">
              <div className="website_div">
                {/* technology_partner start here */}
                <div className="technology_partner">
                  <div className="heading">
                    <h1>Our Technology partners</h1>
                  </div>
                  <div className="company_name">
                    {/* front hand start here */}
                    {/* <div className="front_End">
                      <div className="front_hand_heading">
                        <h2>FRONT END</h2>
                      </div>
                      <div className="frontHand_image">
                        <div className="image_one">
                          <img
                            src="https://www.dwsit.com/upload/html-1619348371.png"
                            alt="html"
                          />
                        </div>
                        <div className="image_two">
                          <img
                            src="https://www.dwsit.com/upload/js-1619348347.png"
                            alt="js"
                          />
                        </div>
                        <div className="image_three">
                          <img
                            src="https://www.dwsit.com/upload/css-1619348301.png"
                            alt="css"
                          />
                        </div>
                        <div className="image_four">
                          <img
                            src="https://www.dwsit.com/upload/vau-1619348425.png"
                            alt="vue"
                          />
                        </div>
                        <div className="image_five">
                          <img
                            src="https://www.dwsit.com/upload/react-1619348453.png"
                            alt="react"
                          />
                        </div>
                        <div className="image_six">
                          <img
                            src="https://www.dwsit.com/upload/angular-1619348479.png"
                            alt="angular"
                          />
                        </div>
                        <div className="image_seven">
                          <img
                            src="https://www.dwsit.com/upload/sass-1619348504.png"
                            alt="sass"
                          />
                        </div>
                        <div className="image_eight">
                          <img
                            src="https://www.dwsit.com/upload/bootstrap-1619348529.png"
                            alt="bootstrap"
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* backhand start here */}
                    {/* <div className="back_End">
                      <div className="back_hand_heading">
                        <h2>BACK END</h2>
                      </div>
                      <div className="backhand_image">
                        <div className="image_one">
                          <img
                            src="https://www.dwsit.com/upload/node-js-logo-small-1620927106.svg"
                            alt="html"
                          />
                        </div>
                        <div className="image_two">
                          <img
                            src="https://www.dwsit.com/upload/php-logo-small-1620927146.svg"
                            alt="js"
                          />
                        </div>
                        <div className="image_three">
                          <img
                            src="https://www.dwsit.com/upload/go-logo-blue-small-1620927168.svg"
                            alt="css"
                          />
                        </div>
                        <div className="image_four">
                          <img
                            src="https://www.dwsit.com/upload/python_1-1620927190.svg"
                            alt="vue"
                          />
                        </div>
                        <div className="image_five">
                          <img
                            src="https://www.dwsit.com/upload/c-plus-plus-1620927229.svg"
                            alt="react"
                          />
                        </div>
                        <div className="image_six">
                          <img
                            src="https://www.dwsit.com/upload/java-logo-vertical-1620927248.svg"
                            alt="angular"
                          />
                        </div>
                        <div className="image_seven">
                          <img
                            src="https://www.dwsit.com/upload/net-1620927292.svg"
                            alt="sass"
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* frame work start here */}
                    <div className="frame_Work">
                      <div className="frame_work_heading">
                        <h2>Frame Work</h2>
                      </div>
                      <div className="framework_image">
                        <div className="image_one">
                          <img
                            src="https://www.dwsit.com/upload/laravel-1623422282.svg"
                            alt="laravel"
                          />
                        </div>
                        <div className="image_two">
                          <img
                            src="https://www.dwsit.com/upload/codeigniter-1623422346.svg"
                            alt="js"
                          />
                        </div>
                        <div className="image_three">
                          <img
                            src="https://www.dwsit.com/upload/zend-1623422381.svg"
                            alt="css"
                          />
                        </div>
                        <div className="image_four">
                          <img
                            src="https://www.dwsit.com/upload/symfony-1623422415.svg"
                            alt="vue"
                          />
                        </div>
                        <div className="image_five">
                          <img
                            src="https://www.dwsit.com/upload/cake-php-1623422443.svg"
                            alt="react"
                          />
                        </div>
                        <div className="image_six">
                          <img
                            src="https://www.dwsit.com/upload/yii-1623422460.svg"
                            alt="angular"
                          />
                        </div>
                      </div>
                    </div>
                    {/* frame work end here */}
                    {/* ecommerce start here */}
                    <div className="ecommerce">
                      <div className="ecommerce_heading">
                        <h2>Ecommerce</h2>
                      </div>
                    <div className="ecommerce_image">
                      <div className="image_one">
                        <img
                          src="https://www.dwsit.com/upload/magento2-1623416274.svg"
                          alt="html"
                        />
                      </div>
                      <div className="image_two">
                        <img
                          src="https://www.dwsit.com/upload/shopify-1623416298.svg"
                          alt="js"
                        />
                      </div>
                      <div className="image_three">
                        <img
                          src="https://www.dwsit.com/upload/woo-1623416552.svg"
                          alt="css"
                        />
                      </div>
                      <div className="image_four">
                        <img
                          src="https://www.dwsit.com/upload/big-1623416603.svg"
                          alt="vue"
                        />
                      </div>
                      <div className="image_five">
                        <img
                          src="https://www.dwsit.com/upload/opencart-1623416646.svg"
                          alt="vue"
                        />
                      </div>
                      <div className="image_six">
                        <img
                          src="https://www.dwsit.com/upload/os-commerce-1623416672.svg"
                          alt="react"
                        />
                      </div>
                      <div className="image_seven">
                        <img
                          src="https://www.dwsit.com/upload/presthashop-1623416794.svg"
                          alt="react"
                        />
                      </div>
                      <div className="image_eight">
                        <img
                          src={shopware}
                          alt="react"
                        />
                      </div>
                      {/* <div className="image_nine">
                        <img
                          src={customize}
                          alt="customize"
                        />
                      </div> */}
                      <div className="image_ten">
                        <img
                          src={wix}
                          alt="wix"
                        />
                      </div>
                      <div className="image_eleven">
                        <img
                          src={woo}
                          alt="woo"
                        />
                      </div>
                    </div>
                    </div>
                    {/* ecommmerce end here */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
};

export default Company;

