import { createGlobalStyle } from "styled-components";

const navbarStyle = createGlobalStyle`
      html {
        font-size: 62.5%;
      }

      :root {
        --font-color: #fff;
        --navbar-btn-bg:#26d170;
        --navbar-btn-hover-bg: #007c65;
        --border-color:#009f81;
      }

      /* Base styles */
      .navbar {
        width: 100%;
        margin: 0 auto;
        border-bottom:1px solid #ddd;
      }

      .navbar-brand {
        width: 16rem;

        img {
          max-width: 100%;
        }
      }

      .navbar-nav {
        width: 42%;
        margin: 0 auto;
        column-gap: 2rem;
        justify-content: center;

        .nav-link {
          font-size: 1.4rem;
          font-weight: 600;
          letter-spacing: 1px;
          white-space: nowrap;
          cursor: pointer;
        }
      }

      .navbar-toggler {
        border: none;

        &:focus {
          box-shadow: none;
        }
      }

      .btn {
        white-space: nowrap;
        background-color: #009f81;
        color: var(--font-color);
        --bs-btn-padding-x: 3rem;
        --bs-btn-padding-y: 0.7rem;
        font-size: 1.4rem;
        display: block;
        -webkit-box-shadow: 0 3px 5px 0 rgba(47,85,212,.3);
        box-shadow: 0 3px 5px 0 rgba(47,85,212,.3);
        border:1px solid #009f81;
        &:hover {
          background: var(--navbar-btn-hover-bg);
          color: var(--font-color);
        }
      }

      /* Media queries */
      @media (max-width: 992px) {
        .navbar {
          width: 90%;
        }

        .navbar-nav {
          width: 100%;
           margin-top: 2rem;
          .nav-link {
            font-size: 1.5rem;
            line-height: 4rem;
          }
        }
        .btn {
          display: none;
        }
      }

      @media (max-width: 640px) {
        .navbar-toggler-icon {
          width: 2.5rem;
          height: 2rem;
        }

        .navbar-brand {
          width: 12rem;
        }
        
        
      }
`;

export default navbarStyle;


