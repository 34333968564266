import styled, { createGlobalStyle } from "styled-components";

const BrandStyle = createGlobalStyle`
*,*::after,*::before{
    box-sizing:border-box;
    padding:0;
    margin:0;
}
:root{
    --font-family:"Montserrat", sans-serif;
    --brand-image-bg:#fff;
    --box-shadow:5px 6px 7px -4px rgba(0, 0, 0, 0.25);
    --box-hover-shadow:-1px 7px 25px -4px rgba(0,0,0,0.25);
   /* --webkit-box-shadow:-webkit-box-shadow: -1px 7px 25px -4px rgba(0,0,0,0.25); */
}
html,body{
    font-size:62.5%;
    font-family:var(--font-family);
}
.brandImage_list_div{
    margin-top:5rem;
    ul{
        display: grid;
        place-content: center;
        column-gap: 8rem;
        grid-template-columns: repeat(3,max-content);
        place-items: center;
        row-gap: 3rem;
        padding-left: 0;
        li{
            list-style: none;
            width:20rem;
            padding:4% 2%;
            background:var(--brand-image-bg);
            box-shadow:var(--box-shadow);
            -webkit-box-shadow:5px 6px 7px -4px rgba(0, 0, 0, 0.25);
            display: grid;
            place-items: center;
            border-radius:0.5rem;
            transition:all 0.3s ease;
            cursor: pointer;
            border: 1px solid #ddd;
            &:hover{
            box-shadow:var(--box-shadow);
            -webkit-box-shadow: -1px 7px 25px -4px rgba(0,0,0,0.25);
            
            }
            a{
                text-decoration: none;
                img{
                    max-width:17rem;
                    height: 5rem;
                }
            }
        }
    }
}
/* media query start here for 1024px  */

@media (max-width:1024px){
.brandImage_list_div{
    ul{
      column-gap:1rem;
      row-gap: 1rem;
    }
}
}
/* media query end here for  1024px */

/* media query start here for 780px */
@media (max-width:780px){
    .brandImage_list_div{
        ul{
            grid-template-columns:repeat(2,max-content);
            li{
            a{
                img{
                    max-width:14rem;
                    height: 4rem;
                }
            }
        }
        }
    }
}
/* media query end here for 780px  */

`;

export default BrandStyle;
