import React from "react";
import ecommerce1 from "../../assets/image/ecomerce/features/ecommerce1.png";
import ecommerce2 from "../../assets/image/ecomerce/features/ecommerce2.png";
import ecommerce3 from "../../assets/image/ecomerce/features/ecommerce3.png";
import ecommerce4 from "../../assets/image/ecomerce/features/ecommerce4.png";
import ecommerce5 from "../../assets/image/ecomerce/features/ecommerce5.png";
import ecommerce6 from "../../assets/image/ecomerce/features/ecommerce6.png";
import ecommerce7 from "../../assets/image/ecomerce/features/ecommerce7.png";
import ecommerce8 from "../../assets/image/ecomerce/features/ecommerce8.png";
import ecommerce9 from "../../assets/image/ecomerce/features/ecommerce9.png";
import ecommerce10 from "../../assets/image/ecomerce/features/ecommerce10.png";
import ecommerce11 from "../../assets/image/ecomerce/features/ecommerce11.png";
import ecommerce12 from "../../assets/image/ecomerce/features/ecommerce12.png";
import FeatureStyle from "../../assets/style/ecomerce/features.js";
import styled from "styled-components";
const Features = () => {
  const iconDiv = styled.div`
     
  `
  return (
    <>
    <FeatureStyle/>
      <div className="main_multi_vendor">
        <div className="second_multi_vendor">
          <div className="multi_vendor_heading">
            <h2 id="multi_vendor_heading" >Core Features of Our eCommerce Website Design Services</h2>
          </div>
          <div className="multi_vendor_icon">
            <ul className="multi_vendor_list">
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce1} alt="ecommerce1" />
                  </div>
                  <h3>
                  Content Management Capabilities</h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce2} alt="ecommerce2" />
                  </div>
                  <h3>
                  Mobile Responsive
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce3} alt="ecommerce3" />
                  </div>
                  <h3>Multiple Payment 
                    <span> Options</span>
                    </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce4} alt="ecommerce4" />
                  </div>
                  <h3>Shipping API 
                    <span>Integration</span>
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce5} alt="ecommerce5" />
                  </div>
                  <h3>
                  Promotion and Discount Management
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce6} alt="ecommerce6" />
                  </div>
                  <h3>
                  Wishlist
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce7} alt="ecommerce7" />
                  </div>
                  <h3>
                  Easy-to-Use 
                    <span>Checkout</span>
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce8} alt="ecommerce8" />
                  </div>
                  <h3>
                  Ratings and 
                    <span>Reviews</span>
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce9} alt="ecommerce9" />
                  </div>
                  <h3>
                  Search Engine 
                    <span>Optimized</span>
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce10} alt="ecommerce10" />
                  </div>
                  <h3>
                  Reporting Tools
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce11} alt="ecommerce11" />
                  </div>
                  <h3>
                  Customers’ Data 
                    <span>Collection</span>
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={ecommerce12} alt="ecommerce12" />
                  </div>
                  <h3>
                  The Ability to Scale 
                    <span>Up</span>
                  </h3>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
