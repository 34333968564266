import styled, { createGlobalStyle } from "styled-components";
const FeatureStyle = createGlobalStyle`
*,*::after,*::before{
    box-sizing:border-box;
    margin:0;
    padding:0;
}
:root{
    --font-family:"Montserrat", sans-serif;
    --multi-card-bg:#6edbff;
    --weight-seven:700;
    --weight-six:600;
    --weight-five:500;
    --weight-four:400;
    --weight-three:300;
    --box-shadow: 5px 6px 7px -4px rgba(0, 0, 0, 0.25);
    --hover-box-shadow:-1px 7px 25px -4px rgba(0,0,0,0.25);
}
html,body{
    font-size:62.5%;
    font-family:var(--font-family);
}
.main_multi_vendor{
    padding:5rem 0;
}
.multi_vendor_heading{
    text-align: center;
    #multi_vendor_heading{
        font-size:3.5rem;
        font-weight:var(--weight-seven);
        color:#fff;
    }
}
.multi_vendor_list{
  display: grid;
  grid-template-columns: repeat(6,max-content);
  column-gap: 1rem;
  place-content: center;
  padding-top: 2rem;
  row-gap: 3rem;
  padding-left: 0;
    .multi_vendor_sublist{
        list-style:none;
        background:var(--multi-card-bg);
        max-width:20rem;
        min-width:10rem;
        border-radius:0.5rem;
        min-height: 11rem;
        padding:0.5rem;
        width: 18rem;
        box-shadow: var(--box-shadow);
        &:hover{
        box-shadow: var( --hover-box-shadow);
        -webkit-box-shadow: -1px 7px 25px -4px rgba(0,0,0,0.25);
        }
        .icon_para{ 
            display: grid;
            place-items:center;
            .image{
                max-width:4rem;
                min-width:3rem;
                margin-bottom: 1rem;
                img{
                    width:100%;
                }
            }
            h3{
                display: flex;
                flex-direction: column;
                font-size:1.3rem;
                font-weight: var(--weight-six);
                text-align: center;
                line-height: 2rem;
            }
        }
    }
}
@media (max-width:1224px){
     html{
        font-size:60%;
     }
    .multi_vendor_list{
  grid-template-columns: repeat(3,max-content);
  row-gap: 1rem;
}
}
@media (max-width:999px){
    .multi_vendor_heading{
    #multi_vendor_heading{
        font-size:2.5rem;
    }
}
}
@media (max-width:640px){
    html{
        font-size:55%;
     }
}
@media (max-width:480px){
    .multi_vendor_list{ 
        .multi_vendor_sublist{
            max-width: 12rem;
            min-width: 10rem;
        }
    }
}
`;
export default FeatureStyle;
