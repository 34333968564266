import styled, { createGlobalStyle } from "styled-components";
const OfficeStyle = createGlobalStyle`
*,*::after,*before{
    box-sizing: border-box;
    padding:0;
    margin:0;
}
:root{
    --font-family: "Montserrat";
    --span-color: #5b626b;
    --border-left-cl:#e9ecef;
}

html,body{
    font-size:62.5%;
}
.office{
     display:grid;
     place-content: center;
     place-items: center;
     margin-top: 5rem;
 
/* office_heading start here */
.office_heading{
    h2{
        display: flex;
        flex-direction: column;
        line-height:4rem;
        text-align: center;
        font-family:var(--font-family),sans-serif;
        font-weight: 600;
        font-size:3.5rem;
        span{
            font-size:1.8rem;
            text-align: center;
            color:var(--span-color);
        }
    }
}
/* office_heading end here */

/* office image start here */
     .office_image{
        display: grid;
        grid-template-columns: repeat(3,max-content);
        place-content: center;
        column-gap: 5rem;
        margin-top:3rem;
        margin-bottom:3rem;
        /* office one image */
        .office_one,
        .office_two{
           max-width:50rem;
           min-width:5rem;
           /* image */
           img{
            width: 100%;
           }
        }
        .border_image{
            border-left:1px solid var(--border-left-cl);
            height:52rem;
        }
     }
/* office image end here */
}
@media (max-width:1024px){
    html{
    font-size:55%;
   }

   .office{
    .office_image{
        column-gap: 2rem;
    }
   }
}
/* media query start here for 992 */
@media (max-width:992px){
   .office{
    .office_image{
        grid-template-columns: repeat(1,max-content);

        /* border */
        .border_image{
            border-left:none;
            height:0rem;
        }
    }
    .office_heading{
    h2{
       font-size:3rem;
       line-height: 3.5rem;
        span{
            font-size:2rem;
            margin-top: 1rem;
        }
    }
}
   }
}
/* media query end here for 992 */
/* media query start here 780 */
@media (max-width:780px){
   .office{
   
    .office_heading{
    h2{
       font-size:2.5rem;
       line-height: 3rem;
    }
}
   }
}
/* media query end here 780 */
@media (max-width:480px){
    .office{
        .office_image{

/* office image */
        .office_one,
        .office_two{
        max-width: 40rem ;
        min-width: 4rem ;
        /* image */
        img{
            width: 100%;
        }
        }
   }
   .office_heading{
    h2{
       font-size:2.5rem;
       line-height: 3rem;
        span{
            font-size:2rem;
            margin-top: 1rem;
        }
    }
}
 }
 /* heading start here */

 
 /* heading end here */
   
}

`;
export default OfficeStyle;
