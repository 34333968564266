import React from 'react';

import Home from './Home'
import Navbar from './Navbar'
import Footer from './Footer'
const IndexLandingpage = () => {
    return (
        <div>
            <Navbar />
            <Home />
            <Footer />
        </div>
    )
}

export default IndexLandingpage;





