import React, { useEffect } from "react";
import image from "../../assets/image/ecomerce/drive/image.png";
import magentoicon from "../../assets/image/ecomerce/drive/magentoicon.png";
import nodeicon from "../../assets/image/ecomerce/drive/nodeicon.png";
import opencarticon from "../../assets/image/ecomerce/drive/opencarticon.png";
import prestaShopicon from "../../assets/image/ecomerce/drive/prestaShopicon.png";
import shopifyicon from "../../assets/image/ecomerce/drive/shopifyicon.png";
import wordpressicon from "../../assets/image/ecomerce/drive/wordpressicon.png";
import DriveStyle from "../../assets/style/ecomerce/drive.js";
const Drive = () => {

 
  return (
    <>
      <DriveStyle />
      <div className="container-fluid">
        <div className="">
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
            <div className="main_drive">
              <div className="second_drive">
                <div className="drive_heading_image">
                  <div className="content-wrapper">
                    <div className="drive_heading">
                      <h2>
                        Platforms Used  
                        <span>Our, qualified </span>
                        <span>eCommerce, Web-</span>
                        <span>site Developers</span>
                      </h2>
                    </div>
                    <div className="drive_image">
                      <img src={image} alt="image" />
                    </div>
                  </div>
                </div>
                {/* finger start here */}
                <div className="drive_fingers">
                  <div className="drive_finger_one">
                    <div className="finger_image">
                    <img src={magentoicon} alt="magentoicon" />
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>Magento Development</h4>
                      <div className="drive_para">
                        <p>
                        Magento is widely used by eCommerce development companies for handling large product inventories and heavy traffic. It is also the top choice among eCommerce developers for multivendor marketplace development. Due to its modular structure, it allows deep customization of both the front-end and back-end. Magento offers useful analytics and reporting tools, such as Abandoned Cart Report, Low Stock Report, Coupon Usage Report, and many more.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="drive_finger_two">
                    <div className="finger_image">
                    <img src={opencarticon} alt="opencarticon" />
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>OpenCart Development</h4>
                      <div className="drive_para">
                        <p>
                        One of the main perks of OpenCart is easy customization. Thanks to the use of AJAX, websites developed using OpenCart are extremely lightweight. It supports over 14000 extensions that can be plugged-in effectively to enhance functionalities of the online store. As a reputable eCommerce development company in India, we can help you create a beautiful online store at a reasonable cost.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="drive_finger_three">
                    <div className="finger_image">
                    <img src={prestaShopicon} alt="prestaShopicon" />
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>PrestaShop Development</h4>
                      <div className="drive_para">
                        <p>
                        When using PrestaShop, you do not have to deal with a lot of technical aspects or complex coding. It is free, lightweight and easy to use. Besides, it offers multilingual support which allows you to sell your products or services in a global market. Our professional eCommerce website developers are well acquainted with this platform and can build unique eCommerce websites for your business.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="drive_finger_four">
                    <div className="finger_image">
                    <img src={wordpressicon} alt="wordpressicon" />
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>WordPress Development</h4>
                      <div className="drive_para">
                        <p>
                        WordPress websites are lightweight, faster to build and extremely easy to manage. We use WooCommerce which is the most popular eCommerce plugin for WordPress with 30% of the market share. Built with REST API, WooCommerce is highly scalable and offers secure online payment, configurable shipping options and many other features required for a fully functional eCommerce website.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="drive_finger_five">
                    <div className="finger_image">
                    <img src={nodeicon} alt="nodeicon" />
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>Custom Development</h4>
                      <div className="drive_para">
                        <p>
                        Do you want your online marketplace to stand out from the crowd, and packed with features and functionalities specific to your business? The services of our experienced eCommerce website developers are just what you need. We can craft a feature-rich, easily navigable, user-friendly, and lightweight custom eCommerce website tailored to your business. We use frameworks like Laravel and NodeJS to develop robust and scalable online stores.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="drive_finger_six">
                    <div className="finger_image">
                    <img src={shopifyicon} alt="shopifyicon" />
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>Shopify Development</h4>
                      <div className="drive_para">
                        <p>
                        Shopify is a fully hosted platform for developing an eCommerce Website quickly and efficiently. With 100+ professional themes and several useful plugins, it takes minimal development efforts. Our Shopify developers can build and deliver an eCommerce storefront for your business with features like Drop Shipping, Fraud Prevention, SEO optimized, Social Media Integration and more.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* finger end here */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Drive;
