// import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import WebsiteHome from "./Components/website/IndexWebsiteDevelopment";
import EcommerceHome from "./Components/ecomerce/IndexEcomerce";
import DwsitHome  from "./Components/dwsitlandingpage/IndexLandingpage";
import DigitalHome from "./Components/digitalmarketing/IndexDigitalmarketing";
function App() {
  return (
<>
<Routes>
  <Route path="/" element={<DwsitHome/>} />
  <Route path="/websitedevelopment" element={<WebsiteHome/>} />
  <Route path="/digitalmarketing" element={<DigitalHome/>} />
  <Route path="/ecommerce" element={<EcommerceHome/>} />
</Routes>
</>
  );
}

export default App;
