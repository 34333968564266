import { createGlobalStyle } from "styled-components";

const homeStyle = createGlobalStyle`
 :root{
    --heading-color:#161c2d;
    --heading-span-color:#e43f52;
    --para-color:black;
    --font-family: "Archivo";
    --second-font-family:"Montserrat Alternates", sans-serif;
    --slider-bg:#0070cc;
 }
 .main_form_container{
    display:none;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);
    z-index:2;
    background-attachment: scroll;
    
    
    .form_container{
        z-index:2;
        display:grid;
        place-content:center;
        margin-top:8rem;    
    }
 }

/* .form_Input{
  height:100%;
  display:grid;
  place-items: center;
  z-index:10000;

} */
.main_home_heading{
    display:grid;
    place-content: center;

    /* home heading start here */
    .home_heading{
        margin-top: 9rem;
        h2{
            font-weight: 800;
            color:var(--heading-color);
            font-size:4.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        
            span{
                /* background-image: linear-gradient(to right , #074C00,#42A341,#B8E2A3);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent; */
                color:#009f81;
            }
        }
    }
    /* home heading end here */
    /* home para start here */
    .home_para{
        margin-top: 2.5rem;
        h5{
            color:var(--para-color);
            font-weight:600;
            font-size:2rem;
            text-align: center;
            padding-left: 2%;
            padding-right: 2%;
            span{
                color: #009f81;
                font-weight: 700;
            }
        }
    }
    /* home para end here */
}
/* left arrow and right arrow start here */
.butn_arrow{
  display: grid;
  grid-template-columns: repeat(3,max-content);
  place-content: center;
  place-items: center;
  column-gap: 2rem;
  margin-top: 1.5rem;
}
.arrow_left_image{
    img{
        width:10rem;
    }
}
.butn{
        padding-left: 50px;
        padding-right: 50px;
        background-color: #009f81;
        border-color:#009f81;
      }
.arrow_right_image{
    img{
        width:10rem;
    }
}
.lower_heading{
    display:grid;
    place-content: center;
    margin-top: 0.5rem;
    h1{
        font-size:2rem;
        font-weight:600;
        color:var(--heading-color);
    }
}
.home_card{
    display:grid;
    place-content: center;
    margin-top: 5rem;
    overflow:hidden;
}
/* left arrow and right arrow end here */

/* home slider start here  */
/* .main_home_slider{
    background-color:var(--slider-bg);
    width: 100%;
    padding:5rem;
    margin-top: 8rem;
} */
.home_slider{
    width:80%;
    margin:8rem auto;
    
}
/* home slider end here */
/* home card heading start here */
.main_card_heading{
    display:grid;
    place-content: center;
    margin-bottom: 5rem;
    h2{
        font-size:3.5rem;
        font-weight: 600;
        font-family:var( --second-font-family);
    }
}
/* home card heading end here */

/* home_features start here */
.home_features{
    background:#0070cc;
}
/* home_features end here */

/* media query for 992 start here */
@media (max-width:992px){
      .main_home_heading {
        .home_heading{
        h2{
            font-size: 3rem;
            text-align: center;
        }
      }
      }
      /* forth button start here */
      
      /* fourth button end here */
    /* Get Exact Quotation start here */
    .butn_arrow{
        column-gap: 0;
    }
    .arrow_butn{
        padding-top: 1rem;
        padding-bottom: 2rem;
    }
    .arrow_left_image,
    .arrow_right_image{
        display: none;
    }
    .butn{
        display: block;
    }
    /* Get Exact Quotation end here */
/* lower heading start here */
.lower_heading {
    h1{
    text-align: center;
}
}
/* lower heading end here */

}
@media (min-width:800px) and (max-width:992px){
.main_form_container {
    .form_container {
    margin-top:1rem !important;
}
}
}
@media (min-width:640px) and (max-width:800px){
.main_form_container {
    .form_container {
    margin-top:1rem !important;
}
}
.main_card_heading{
    h2{
        font-size:3rem;
    }
}
}
@media (max-width:640px){
.main_form_container {
    .form_container {
    margin-top:1rem !important;
}
}
.main_card_heading{
    h2{
        font-size:2.5rem;
    }
}
}

@media (max-width:360px){
    .main_form_container{
        .form_container{
            margin-top:1rem;
        }
    }
}


/* media query for 992 end here */
`;
export default homeStyle;
