import styled, { createGlobalStyle } from "styled-components";
const CaseStyle = createGlobalStyle`
*,*::after,*::before{
    padding:0;
    margin:0;
    box-sizing: border-box;
}
:root{
    --span-color:#5b626b;
    --font-family:"Montserrat", sans-serif;
    --view-butn-bg:#26d170;
}
html{
    font-size:62.5%;
}
.main_caseStudies{
  margin-bottom: 10rem;
  background: #19367a17;
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.caseStudies{
    display: grid;
    place-content: center;
    text-align: center;
    margin-bottom: 2rem;
    .caseStudies_strong_heading{
        margin: 1rem;
        h2{
            font-size:3rem;
            font-family:var(--font-family);
            font-weight: 600;
        }
    }
    .caseStudies_para_one,
    .caseStudies_para_two{
        width: 50%;
        margin: 0 auto;
        span{
           color:var(--span-color);
           font-size:1.8rem;
           font-weight: 500;
           text-align: center;
          
        }
    }
    /* caseStudies ViewAll btn */
    .caseStudies_viewAll_btn{
        display: grid;
        place-content: center;
        margin: 3rem;
        margin-bottom: 4rem;
        button{
            color:#fff;
             background:var(--view-butn-bg);
             outline:none;
             font-size:1.5rem;
             font-weight: bold;
             border:1px solid var(--view-butn-bg);
        }
    }
    /* caseStudies ViewAll btn */
}
.caseStudies_images{
    display: grid;
    place-content: center;
    grid-template-columns: repeat(6,max-content);
    column-gap: 1rem;
    row-gap: 5rem;
    .case_image_one,
    .case_image_two,
    .case_image_three,
    .case_image_four,
    .case_image_five,
    .case_image_six,
    .case_image_seven,
    .case_image_eight,
    .case_image_nine,
    .case_image_ten,
    .case_image_eleven,
    .case_image_twelve{
        max-width:15rem;
        min-width:4rem;
        border: 1px solid #5b626b4f;
        border-radius: 0.5rem;
        img{
          width: 100%;
          border-radius:0.5rem;
        }
    }
}

/* @media query start here for 1024px */
@media (max-width:1024px){
    .caseStudies{
        .caseStudies_strong_heading{
        h2{
            font-size:2.5rem;
        }
    }
    .caseStudies_para_one,
    .caseStudies_para_two{
        span{
           font-size:1.5rem;
        }
    }
   
}
.caseStudies_images{
    grid-template-columns: repeat(4,max-content);
      }
}
@media (max-width:780px){
    .caseStudies_images{
        grid-template-columns:repeat(3,max-content);
    }
}
@media (max-width:360px){
    .caseStudies_images{
        row-gap: 2rem;
    .case_image_one,
    .case_image_two,
    .case_image_three,
    .case_image_four,
    .case_image_five,
    .case_image_six,
    .case_image_seven,
    .case_image_eight,
    .case_image_nine,
    .case_image_ten,
    .case_image_eleven,
    .case_image_twelve{
        max-width:12rem;
        min-width:3rem;
        img{
          width: 100%;
        }
    }
}
}
`;
export default CaseStyle;
