import styled, { createGlobalStyle } from "styled-components";
const SliderStyle = createGlobalStyle`
*,*::after,*::before{
  padding:0;
  margin:0;
  box-sizing: border-box;
}
:root{
 
    --slider-para-color:#000;
    --slider-card-bg:#6edbff;
    --font-family: "Montserrat", sans-serif;
    --slider-name-color:#000;
    --font-six:600;
    --font-five:500;
    --font-four:400;
    --font-three:300;
}
html,body{
    font-size:62.5%;
    font-family:var(--font-family);
}

.main_google_slider{
    background:var(--slider-card-bg);
    width:296px;
    height: 25rem;
    border-radius: 0.8rem;
.google_image_logo{
    display: grid;
    place-content: space-between;
    grid-template-columns: repeat(2,max-content);
    padding-top: 2rem;
    place-items: center;
    padding-left: 9%;
    padding-right: 9%;
    .google_image_year{
        display: grid;
        grid-template-columns: repeat(2,max-content);
        column-gap: 2rem;
        place-items: center;
        /* google image start here */
        .google_image{
            max-width: 6rem;
            min-width:3rem;

            img{
                width: 100%;
            }
        }
        /* google image end here */
        /* google heading start here */
        .google_heading{
            line-height: 2rem;
            display: grid;
            padding-top: 1rem;
            h4{
                font-size:1.5rem;
                color:var(--slider-name-color);
                font-weight: var(--font-six);
                justify-self: baseline ;
            }
            p{
                font-size:1.2rem;
                color:var(  --slider-para-color);
                font-weight: var(--font-five);
                justify-self: baseline;
                margin-bottom: 0;
            }
        }
        /* google heading end here */
    }
    /* google logo start here */
    .google_logo{
        /* margin-top: -1.5rem; */
    }
    /* google logo end here */
  
}
  /* google star start here */
  .google_star{
        display: grid;
        place-content: start;
        margin-left: 3rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

       
    }
    /* google star end here */
    /* google para start here */
    .google_para{
         width: 100%;
        p{
            font-family: var(--font-family);
            font-weight: var(--font-five);
            font-size:1.5rem;
            width: 80%;
            margin: 0.5rem auto;
            text-align: start;
            padding-bottom:5%;
            color:var(  --slider-para-color);
        }
    } 
    /* google para end here */
}
.swiper-button-prev:after, .swiper-button-next:after{
    font-size:2rem !important;
  
}
.swiper-button-prev{
    margin-left: -1rem;
}
.swiper-button-next{
    margin-right: -1rem;
}
`;

export default SliderStyle;