import React from 'react';
import processImage from "../../assets/image/digitalmarketing/websiteWork/process.png"
import WebsiteworkStyle from '../../assets/style/digitalmarketing/websitework.js';
const WebsiteWork = () => {
  return (
    <>
    <WebsiteworkStyle/>
     <div className='main_websitework'>
       <div className="second_websitework">
        {/*  */}
        <div className="websiteWork">
            <div className="websiteWork_heading">
                <h2>
                    Process we follow to deliver your website
                    <span>
                        Our Process is appreciated by brands like Emami, Volkswagon, Tata and many more
                    </span>
                </h2>
            </div>
            <div className="websiteWork_image">
                <img src={processImage} alt="processImage" />
            </div>
        </div>
        {/*  */}
       </div>
     </div>
    </>
  )
}

export default WebsiteWork;