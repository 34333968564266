import styled, { createGlobalStyle } from "styled-components";
import pricePatternOne from "../../image/website/card/price-pattern-1.png";
import pricePatternTwo from "../../image/website/card/price-pattern-3.png";
const CardStyle = createGlobalStyle`
*,*::before,*::after{
    padding:0;
    margin:0;
    box-sizing:border-box;
}
:root{
    --font-family: "Archivo";
    --font-weight:400;
    --font-butn-weight:600;
    /* --linear-gradient:linear-gradient(90deg,#fc2828 0%,#f27529 80%); */
    --linear-gradient:  linear-gradient(90deg, rgba(38,209,112,1) 0%, rgba(38,209,112,1) 35%, rgba(38,209,112,1) 100%);
    --font-color:#fff;
    --border-color:#294eb9;
    --color:#000;
    --check-icon-color:#2939c6;
    --sec-card-bg-color: transparent;
    --sec-card-bg-image:linear-gradient(180deg,#151ab2 0%,#4c68e2 100%);
    --sec-card-list-cl:#fff;
    --sec-first-list-cl:#FEA724;
}
html,body{
    font-size:62.5%;
    user-select: none;
}
.main_cards{
    display: grid;
    grid-template-columns:repeat(3,max-content);
    column-gap: 5rem;
}
.fourth_card{
    .one_cards{
        height:210rem;
    }
    
}
.fiveth_card{
    .one_cards{
        height:210rem !important;
    }
    
}
.sixth_card{
    .one_cards{
       height: 210rem;
    }
}
.one_cards{
 width:36rem;
 border:1px solid var(--border-color);
 border-radius:0.5rem;
 display: grid;
 place-items: center;
 margin-bottom:3rem;
 background: url(${pricePatternOne});
 background-repeat: no-repeat;
 background-position: bottom center;
/* card button start here */

.card_button{
    display: grid;
    place-content: center;
    margin-top: 1.2rem;
    button{
        background:transparent;
        outline:none;
        border:none;
        background-image:var(--linear-gradient);
        padding: 1rem 2rem 1rem 2rem;
        color:var(--font-color);
        font-family: var(--font-family),sans-serif;
        font-weight:var(--font-butn-weight);
        font-size:1.8rem;
        border-radius:1rem;
    }
}

/* large button css start here */
.card_large_butn{
    display:flex;
    flex-direction:column;
    padding:1.2rem 2rem;
    line-height:2rem;       
    width:31rem;
    white-space:nowrap;     
    align-items:center;
}
/* large button css end here */
/* card button end here */
/* card image start here */
  .card_image{
    max-width:22rem;
    max-height:27rem;
    min-width:10rem;
    min-height:8rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    img{
     width:100%;
     height:100%;
    }
  }
/* card image end here */

/* first list start here */

    .card_list{
       .sublist_one{
        list-style: none;
        font-family: var(--font-family),sans-serif;
        font-weight: var(--font-weight);
        display: flex;
        flex-direction: column;
        font-size: 3.2rem;
        text-align: center;
        
        h5{
            font-size:1.5rem;
            margin-bottom:3.5rem;
        }
       }
       .sublist_one:nth-child(1){
            margin-bottom:3rem;
        }
        .sublist_one:nth-child(3){
            h5{
                font-size:1.8rem;
            }
        }
    }

/* first list end here */


/* second list start here */
.second_ul_list{
  .second_list{
 
    .second_sublist{
        color: var(--color);
        font-family: var(--font-family),sans-serif;
        font-weight: var(--font-weight);
        font-size: 1.5rem;
        line-height: 1.5;
        list-style: none;
        margin-top: calc(20px/2);
        padding-bottom: calc(20px/2);
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        align-items: center;

        /* icons css start here */
        span{
            i{
 
                color: var(--check-icon-color);
                font-size: 2rem;
                width: 3rem;
            }
        }
        /* icons css end here */
    }
  }
}
/* second list end here */
/* click now button start here */
.click_now{
            width:12rem;
            height: 4.5rem;
            background-color: #26d170;
            display: grid;
            place-content: center;
            border-radius:0.5rem;
            cursor: pointer;
            margin-top: 1.5rem;
            margin-bottom: 8rem;
    a{
        text-decoration: none;
        span{
            color:#202020;
            font-family:var(--font-family),Sans-serif;
            font-size:1.5rem;
            white-space: nowrap;        
            font-weight: 500;
            color: #fff;
        }
    }
}
.click_now:focus{
    border:1px solid blue;
}
}
.two_card{

    .one_cards{
    width:36rem;
    height: max-content;
    background-image:var(--sec-card-bg-image);
    background-color:var(--sec-card-bg-color);

    /* first card list start here */
    .card_list{
        .sublist_one{
           color:var(--sec-card-list-cl);
        }
       
    }
    /* first card list end here */

    /* second list start here */
    .second_list{
        margin-bottom: 2.5rem;
        .second_sublist{
            color:var(--sec-card-list-cl);
            span{
                i{
                    color:var(--sec-card-list-cl)
                }
            }
        }
    }
  /* footer image start here */
    .footer_image{
      background-image: url(${pricePatternTwo});
      background-size: cover;
      background-position:  center;
      background-repeat: no-repeat;
      width: 100%;
      height:12rem;
      display: grid;
      place-content: center;

      /* click button start now */
      .click_now{
        margin-top: 3.5rem;
        margin-bottom: 4rem;
    }
    /* click button end now */
    }
}
}
@media (max-width:1200px){
.main_cards{
    grid-template-columns: repeat(2,max-content) ;
    display: grid;
    place-content: center;
}
.one_cards{
    .card_button{

    button{
        font-size:1.7rem;
    }
}
}
}
/* media query start here for 1024px */

@media (max-width:1024px){
.main_cards{
    grid-template-columns: repeat(2,max-content) ;
    display: grid;
    place-content: center;
}
}
/* media query end here for 1024px */
@media (max-width:992px){

.main_cards{
    grid-template-columns: repeat(2,max-content) ;
    display: grid;
    place-content: center;
}

}
/* media query for 800px start here */
@media (max-width:800px){
    .main_cards{
    grid-template-columns: repeat(1,max-content) ;
}

}
/* media query foir 800px end here */
@media (max-width:640px){
.main_cards{
    grid-template-columns: repeat(1,max-content) ;
}
.fourth_card{
    .one_cards{
        height:235rem;
    }
}
.fiveth_card{
    .one_cards{
        height:210rem !important;
    }
}
.sixth_card{
    .one_cards{
       height: 235rem;
    }
}
}


@media (max-width:360px){
.one_cards{
    width: 35rem ;
    margin: 1rem auto;
}
.two_card{
    .one_cards{
        width: 35rem ;
        margin: 1rem auto;
    }
    
}
.fourth_card{
    .one_cards{
        height:250rem;
    }
}
.fiveth_card{
    .one_cards{
        height:230rem !important;
    }
}
.sixth_card{
    .one_cards{
       height: 250rem;
    }
}
}
`;

export default CardStyle;
