import styled, { createGlobalStyle } from "styled-components";

const WebsiteworkStyle = createGlobalStyle`
*,*::after,*::before{
    padding:0;
    margin:0;
    box-sizing:border-box;
}
:root{
    --font-family:"Montserrat", sans-serif;
    --font-weight-one: 500;
    --span-color:#5b626b;
}
html,body{
    font-size:62.5%;
    font-family:var(--font-family);
    
}

.websiteWork{
    display: grid;
    place-content: center;
    background: #f1f1f1;
    padding-top: 3rem;
    padding-bottom: 3rem;
    .websiteWork_heading{
        display:grid;
        place-content: center;
        h2{
            display:flex;
            flex-direction:column;
            justify-content: center;
            text-align: center;
            font-size:3.5rem;
            line-height: 6rem;
            font-weight: 600;
            font-family: var(--font-family);
            span{
              font-size:1.8rem;
              font-weight:var(--font-weight-one);
              color:var(--span-color);
            }
        }
    }
    /* image start here */
   
    .websiteWork_image{
            
            max-width:115rem;
            min-width:10rem;
            /* margin-top: 1rem; */
            img{
                width:100%;
            }
    }
    /* image end here */
}
/* media query start here */
@media (max-width:992px){
 .websiteWork{
    
    margin-top: 2rem;
    margin-bottom:5rem;
    .websiteWork_heading{
        h2{
            line-height: 5rem;
        }
    }
    /* image start here */
    .websiteWork_image{
       margin-top:1rem;
    }
    /* image end here */
 }
};
@media (max-width: 800px){
    .websiteWork{
    .websiteWork_heading{
        h2{
            font-size:3rem;
            line-height: 3.5rem;
            span{
              line-height: 3rem;
              margin-top: 1rem;
            }
        }
    }
 }
}
@media (max-width:640px){
    .websiteWork{
    .websiteWork_heading{
        h2{
            font-size:3rem;
            line-height: 4rem;
            span{
              line-height: 3rem;
              margin-top: 1rem;
            }
        }
    }
 }
}

@media (max-width:480px){
    .websiteWork{
    .websiteWork_heading{
        h2{
            font-size:2.5rem;
            line-height:3rem;
            span{
              font-size:1.8rem;
            }
        }
    }
 }
}
/* media query end here */
`;
export default WebsiteworkStyle;
