import React from 'react'
import Navbar from '../dwsitlandingpage/Navbar'
import Home from "./Home";
import Footer from '../dwsitlandingpage/Footer';
const IndexWebsiteDevelopment = () => {
  return (
    <div>
        <Navbar/>
        <Home/>
        <Footer/>
    </div>
  )
}

export default IndexWebsiteDevelopment