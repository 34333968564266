import React from "react";
import buddhaHall from "../../assets/image/dwsitlandingpage/caseStudies/buddhaHall.jpeg";
import click2door from "../../assets/image/dwsitlandingpage/caseStudies/click2door.jpeg";
import ebay from "../../assets/image/dwsitlandingpage/caseStudies/ebay.jpeg";
import ecommerceStore from "../../assets/image/dwsitlandingpage/caseStudies/ecommerceStore.jpeg";
import indiagate from "../../assets/image/dwsitlandingpage/caseStudies/indiagate.jpeg";
import keaJewels from "../../assets/image/dwsitlandingpage/caseStudies/keaJewels.jpeg";
import patiaalaHouse from "../../assets/image/dwsitlandingpage/caseStudies/patiaalaHouse.jpeg";
import personalSaint from "../../assets/image/dwsitlandingpage/caseStudies/patiaalaHouse.jpeg";
import prespl from "../../assets/image/dwsitlandingpage/caseStudies/prespl.jpeg";
import priceandbuckland from "../../assets/image/dwsitlandingpage/caseStudies/priceandbuckland.jpeg";
import sanketSane from "../../assets/image/dwsitlandingpage/caseStudies/sanketSane.jpeg";
import vehemencePictures from "../../assets/image/dwsitlandingpage/caseStudies/vehemencePictures.jpeg";
import CaseStyle from "../../assets/style/dwsitlandingpage/casestudies.js"
const CaseStudies = () => {
  return (
    <>
    <CaseStyle/>
      <div className="main_caseStudies">
        <div className="second_caseStudies">
          <div className="caseStudies">
            <div className="caseStudies_para_one">
              <span>We create premium designs and technology</span>
            </div>
            <div className="caseStudies_strong_heading">
              <h2>Our Case Studies</h2>
            </div>
            <div className="caseStudies_para_two">
              <span>
                For decades, we've been delivering unmatched results across a
                variety of industries. Browse our case studies to hear from some
                of our customers.
              </span>
            </div>
            {/* <div className="caseStudies_viewAll_btn">
              <button className="btn d-block">View All</button>
            </div> */}
          </div>
          {/* slider start here */}
          <div className="caseStudies_images">
            <div className="case_image_one">
              <img src={ecommerceStore} alt="ecommerceStore" />
            </div>
            <div className="case_image_two">
              <img src={patiaalaHouse} alt="patiaalaHouse" />
            </div>
            <div className="case_image_three">
              <img src={keaJewels} alt="keaJewels" />
            </div>
            <div className="case_image_four">
              <img src={sanketSane} alt="sanketSane" />
            </div>
            <div className="case_image_five">
              <img src={ebay} alt="ebay" />
            </div>
            <div className="case_image_six">
              <img src={buddhaHall} alt="buddhaHall" />
            </div>
            <div className="case_image_seven">
              <img src={indiagate} alt="indiagate" />
            </div>
            <div className="case_image_eight">
              <img src={personalSaint} alt="personalSaint" />
            </div>
            <div className="case_image_nine">
              <img src={priceandbuckland} alt="priceandbuckland" />
            </div>
            <div className="case_image_ten">
              <img src={click2door} alt="click2door" />
            </div>
            <div className="case_image_eleven">
              <img src={prespl} alt="prespl" />
            </div>
            <div className="case_image_twelve">
              <img src={vehemencePictures} alt="vehemencePictures"/>
            </div>
          </div>
          
          {/* slider end here */}
        </div>
      </div>
    </>
  );
};

export default CaseStudies;
