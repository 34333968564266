import React from "react";
import finger from "../../assets/image/ecomerce/multivendor/finger.png";
import heart from "../../assets/image/ecomerce/multivendor/heart.png";
import pantone from "../../assets/image/ecomerce/multivendor/pantone.webp";
import stakeholder from "../../assets/image/ecomerce/multivendor/stakeholders.webp";
import strategic from "../../assets/image/ecomerce/multivendor/strategic.png";
import mechine from "../../assets/image/ecomerce/multivendor/mechine.png";
import MultiStyle from "../../assets/style/ecomerce/multivendor";
const MultiVendor = () => {
  return (
    <>
    <MultiStyle/>
      <div className="main_multi_vendor">
        <div className="second_multi_vendor">
          <div className="multi_vendor_heading">
            <h2>Multivendor Marketplace Development</h2>
          </div>
          <div className="multi_vendor_icon">
            <ul className="multi_vendor_list">
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={finger} alt="finger" />
                  </div>
                  <h3>
                    Vendor Registration &<span>Management</span>
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={pantone} alt="pantone" />
                  </div>
                  <h3>
                    Product Catalog
                    <span>Management</span>
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={mechine} alt="mechine" />
                  </div>
                  <h3>Search and Filtering</h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={stakeholder} alt="stakeholder" />
                  </div>
                  <h3>Order Management</h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={heart} alt="heart" />
                  </div>
                  <h3>
                    Commission & Fee
                    <span>Management</span>
                  </h3>
                </div>
              </li>
              <li className="multi_vendor_sublist">
                <div className="icon_para">
                  <div className="image">
                    <img src={strategic} alt="strategic" />
                  </div>
                  <h3>
                    Analytics and
                    <span>Reporting</span>
                  </h3>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiVendor;
