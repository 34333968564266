import React from "react";
import HomeStyle from "../../assets/style/dwsitlandingpage/home.js";
import leftArrow from "../../assets/image/dwsitlandingpage/home/left_arrow.png";
import rightArrow from "../../assets/image/dwsitlandingpage/home/right_arrow.webp";
// import Cards from "./Cards";
import Form from "./Form";
import Brands from "./Brands";
import Company from "./Company";
import Office from "./Office";
import WebsiteWork from "./WebsiteWork";
import ServiceCard from "./ServiceCard";
import Customer from "./Customer";
import Slider from "./Slider";
import CaseStudies from "./CaseStudies";
const HomeBody = () => {
  const formHandler = () => {
    const showHide = document.querySelector(".main_form_container");
    console.log("=>", showHide);
    showHide.style.display = "block";
  };

  // const hideFormHandler = () => {
  //   const formContainer = document.querySelector(".main_form_container");
  //   formContainer.style.display = "none";
  // };
  window.onclick = function (event) {
    const formContainer = document.querySelector(".main_form_container");
    if (event.target === formContainer) {
      formContainer.style.display = "none";
    }
  };
  return (
    <>
      <HomeStyle />
      <div className="main_form_container" >
        {/* <div className="xmark_div" onClick={hideFormHandler}>
          <i class="fa-solid fa-xmark"></i>
        </div> */}
        <div className="form_container">
          <Form />
        </div>
      </div>

      <div className="container-fluid">
        <div className="">
          {/* heading start here */}
          <div className="main_home_heading">
            <div className="home_heading">
              <h1>
                Best website design company in <span>India</span>
              </h1>
            </div>
            {/* para start here */}
            <div className="home_para">
              <h5>
                Our pricing starts form <span>₹55,000</span> onwards to find the
                exact
              </h5>
              <h5>quotation use the below button</h5>
            </div>
            {/* para end here */}
          </div>
          {/* heading end here */}
          {/* button and arrow start here */}
          <div className="main_butn_arrow">
            <div className="butn_arrow">
              {/* leftArrow */}
              <div className="arrow_left_image">
                <img src={leftArrow} alt="riht_arrow" />
              </div>
              {/* arrow butn */}
              <div className="arrow_butn">
                <button class="butn btn" type="submit" onClick={formHandler}>
                  Get Exact Quotation
                </button>
              </div>
              {/* rightArrow */}
              <div className="arrow_right_image">
                <img src={rightArrow} alt="riht_arrow" />
              </div>
            </div>
            <div className="lower_heading">
              <h1>Join 500+ happy customers from India</h1>
            </div>
          </div>
          {/* button and arrow end here */}

          {/* brand section start here */}
          <div className="home_brands">
            <Brands />
          </div>
          {/* brand section end here */}

          {/* website Name section start here */}
          <div className="home_company">
            <Company />
          </div>
          {/* webstie Name section end here */}

          {/* office start here */}
          <div className="home_office" id="homeOffice">
            <Office />
          </div>
          {/* office end here */}

          {/* WebsiteWork start here */}
          <div className="home_websiteWork">
            <WebsiteWork />
          </div>
          {/* Webtsite Work end here */}

          {/* service cards start here */}
          <div className="home_serviceCard" id="homeServiceCard">
            <ServiceCard />
          </div>
          {/* service cards end here */}

          {/* cards start here */}
          {/* <div className="home_card" id="homeCards">
          <div className="main_card_heading">
              <h2>Packages</h2>
          </div>
            <Cards />
          </div> */}
          {/* cards  end here */}
          
          {/* caseStudies start here */}
           <div className="home_caseStudies" id="homeourcaseStudies">
            <CaseStudies/>
           </div>
          {/* caseStudies  end here */}

          {/* slider start here */}
          <div className="home_slider">
            <Slider />
          </div>
          {/* slider end here */}

          {/* customer section start here */}
          <div className="home_customer">
            <Customer />
          </div>
          {/* customer section end here */}
        </div>
      </div>
    </>
  );
};

export default HomeBody;
