import styled, { createGlobalStyle } from "styled-components";
const FooterStyle = createGlobalStyle`
*,*::after,*::before{
    padding: 0;
    margin:0;
    box-sizing:border-box;
}
:root{
    --footer-color:#002037;
    --footer-heading_color:#f7f7f7;
}
.footer{
    display:grid;
    place-content: space-between;
    grid-template-columns: repeat(2,max-content);
    column-gap: 1rem;
    background:var(--footer-color);
    margin-top: 5rem;
    height: 10rem;
    line-height: 10rem;
    place-items: center;
    padding-left: 5%;
    padding-right: 5%;
    .footer_dwsit_with_year{
        h1{
            color:var(--footer-heading_color);
            font-size:1.8rem ;
        }
    }
    .footer_payment_logo{
        display: grid;
        grid-template-columns: repeat(5,max-content);
        column-gap: 1rem;

        .footer_logo_one,
        .footer_logo_two,
        .footer_logo_three,
        .footer_logo_four,
        .footer_logo_five{
            max-width:4rem;
            min-width:2rem;
            img{
                width: 100%;
            }
        }
    }
}

@media (max-width:480px){
    .footer{
        grid-template-columns: repeat(1,max-content);
        place-content: center;
        line-height: 0;
    }
}
`

export default FooterStyle;