import React from "react";
import brandImageOne from "../../assets/image/digitalmarketing/brandImage/brandImage_one.png";
import brandImageTwo from "../../assets/image/digitalmarketing/brandImage/brandImage_two.png";
import brandImageThree from "../../assets/image/digitalmarketing/brandImage/brandImage_three.svg";
import brandImageFour from "../../assets/image/digitalmarketing/brandImage/brandImage_four.png";
import brandImageFive from "../../assets/image/digitalmarketing/brandImage/brandImage_five.png";
import brandImageSix from "../../assets/image/digitalmarketing/brandImage/brandImage_six.svg";
import brandImageSeven from "../../assets/image/digitalmarketing/brandImage/brandImage_seven.png";
import brandImageEight from "../../assets/image/digitalmarketing/brandImage/brandImage_eight.png";
import brandImageNine from "../../assets/image/digitalmarketing/brandImage/brandImage_nine.png";
import BrandStyle from "../../assets/style/digitalmarketing/brand";
const Brands = () => {
  return (
    <>
      <BrandStyle />
      <div className="container-fluid">
        <div className="">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {/*  */}
            <div className="main_brandImage_div">
              <div className="second_bradImage_div">
                <div className="brandImage_list_div">
                  <ul className="brand_image_list">
                    <li className="brand_image_sublist">
                      <a href="#">
                        <img src={brandImageOne} alt="brandImageOne" />
                      </a>
                    </li>
                    <li className="brand_image_sublist">
                      <a href="#">
                        <img src={brandImageTwo} alt="brandImageTwo" />
                      </a>
                    </li>
                    <li className="brand_image_sublist">
                      <a href="#">
                        <img src={brandImageThree} alt="brandImageThree" />
                      </a>
                    </li>
                    <li className="brand_image_sublist">
                      <a href="#">
                        <img src={brandImageFour} alt="brandImageFour" />
                      </a>
                    </li>
                    <li className="brand_image_sublist">
                      <a href="#">
                        <img src={brandImageFive} alt="brandImageFive" />
                      </a>
                    </li>
                    <li className="brand_image_sublist">
                      <a href="#">
                        <img src={brandImageSix} alt="brandImageSix" />
                      </a>
                    </li>
                    <li className="brand_image_sublist">
                      <a href="#">
                        <img src={brandImageSeven} alt="brandImageSeven" />
                      </a>
                    </li>
                    <li className="brand_image_sublist">
                      <a href="#">
                        <img src={brandImageEight} alt="brandImageEight" />
                      </a>
                    </li>
                    <li className="brand_image_sublist">
                      <a href="#">
                        <img src={brandImageNine} alt="brandImageNine" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
