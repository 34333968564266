import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Home from "./Home"
const IndexDigitalmarketing = () => {
  return (
    <div> 
    <Navbar />
    <Home />
    <Footer />           
    </div>
  )
}

export default IndexDigitalmarketing;