import React from "react";
import ArtboardOne from "../../assets/image/website/card/Artboard-1.webp";
import ArtboardTwo from "../../assets/image/website/card/Artboard-2.webp";
import CardStyle from "../../assets/style/website/card.js";
import ArtboardThree from "../../assets/image/website/card/Artboard-3-1.webp";
import ArtboardFour from "../../assets/image/website/card/im-image-section-124.webp";
import ArtboardFiveth from "../../assets/image/website/card/im-image-section-123.webp";
const Card = () => {
   const formHandler = () => {
    const showHide = document.querySelector(".main_form_container");
    console.log("=>", showHide);
    showHide.style.display = "block";
  };
  return (
    <>
      <CardStyle />
      <div className="container-fluid">
        <div className="">
          {/* main_cards start here */}
          <div className="main_cards">
           
            {/* one card start here */}
            <div className="one_cards">
              {/* card button start here */}
              <div className="card_button">
                <button>Standard Plan</button>
              </div>
              {/* card button end here */}
              <div className="card_image">
                <img src={ArtboardOne} alt="card_image" draggable="false" />
              </div>
              <div className="card_ul_list_one">
                <ul className="card_list">
                  <li className="sublist_one">
                    <small>
                      <strike>₹ 10,000</strike>
                    </small>
                    <b style={{ color: "#151AB2" }}> ₹ 7,999</b>
                  </li>
                  <li className="sublist_one">
                    <h5>( + 18% GST ₹ 1440)</h5>
                  </li>
                  <li className="sublist_one">
                    <h5>Features Includes</h5>
                  </li>
                </ul>
              </div>
              {/* second list start here */}
              <div className="card_second_ul_list">
                <div className="second_ul_list">
                  <ul className="second_list">
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>5 pages Website</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>1 Year Free Domain Name ( .com .in .org )</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>1 Year Free Hosting ( Unlimited Space )</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>Dynamic Website ( Premium Design )</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>Admin Access</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>Lifetime 24/7 Free Hosting Support</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>Unlimited Images & Videos Upload</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>Free SSL Certificates</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>5 Free Email Id</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>SEO Friendly Website</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>100% Responsive Website</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>Live Chat Integration</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>Payment Gateway Integration</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>Social Media Integration</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>Call Button Integration</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>WhatsApp Button Integration</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>Inquiry Form</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>1 Year Free Technical Support For Website</span>
                    </li>
                    <li className="second_sublist">
                      <span>
                        <i aria-hidden="true" class="fas fa-check"></i>
                      </span>
                      <span>Annual Renewal For Hosting Rs.3000.</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* second list end here */}
              <div className="click_now" onClick={formHandler}>
                <a >
                  <span className="click_now_butn">Click Now</span>
                </a>
              </div>
              {/* footer image start here */}
              {/* <div className="card_footer_image">
              </div> */}
              {/* footer image end here */}
            </div>
            {/* one card end here */}

            {/* two card start here */}
            <div className="two_card">
              <div className="one_cards">
                {/* card button start here */}
                <div className="card_button">
                  <button>Premium Plan</button>
                </div>
                {/* card button end here */}
                <div className="card_image">
                  <img src={ArtboardTwo} alt="card_image" draggable="false" />
                </div>
                <div className="card_ul_list_one">
                  <ul className="card_list">
                    <li className="sublist_one">
                      <small>
                        <strike>₹ 17,000</strike>
                      </small>
                      <b style={{ color: "var(--sec-first-list-cl)" }}>
                        ₹ 13,999
                      </b>
                    </li>
                    <li className="sublist_one">
                      <h5>( + 18% GST ₹ 2520 )</h5>
                    </li>
                    <li className="sublist_one">
                      <h5>Features Includes</h5>
                    </li>
                  </ul>
                </div>
                {/* second list start here */}
                <div className="card_second_ul_list">
                  <div className="second_ul_list">
                    <ul className="second_list">
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>12 pages Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Domain Name ( .com .in .org )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Hosting ( Unlimited Space )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Dynamic Website ( Premium Design )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Admin Access</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Google Search Console Setup</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Lifetime 24/7 Free Hosting Support</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Unlimited Images & Videos Upload</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Free SSL Certificates</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>10 Free Email Id</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>SEO Friendly Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>100% Responsive Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Live Chat Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Payment Gateway Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Social Media Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Call Button Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>WhatsApp Button Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Inquiry Form</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Woocommece Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Technical Support For Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Annual Renewal For Hosting Rs.3000.</span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* second list end here */}
                <div className="footer_image">
                  <div className="click_now" onClick={formHandler}>
                    <a >
                      <span className="click_now_butn">Click Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* two card end here */}
            {/* third card start here */}
            <div className="three_card">
              <div className="one_cards">
                {/* card button start here */}
                <div className="card_button">
                  <button>Custom Plan</button>
                </div>
                {/* card button end here */}
                <div className="card_image">
                  <img src={ArtboardThree} alt="card_image" draggable="false" />
                </div>
                <div className="card_ul_list_one">
                  <ul className="card_list">
                    <li className="sublist_one">
                      <small>
                        <strike>₹ ???</strike>
                      </small>
                      <b style={{ color: "var(--sec-first-list-cl)" }}>
                        ₹ ????
                      </b>
                    </li>
                    <li className="sublist_one">
                      <h5>( + 18% GST Applicable )</h5>
                    </li>
                    <li className="sublist_one">
                      <h5>Features Includes</h5>
                    </li>
                  </ul>
                </div>
                {/* second list start here */}
                <div className="card_second_ul_list">
                  <div className="second_ul_list">
                    <ul className="second_list">
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Pages: According to Requirement</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Domain Name ( .com .in .org )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Hosting ( Unlimited Space )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Dynamic Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Admin Access​</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Google Search Console Setup</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Lifetime 24/7 Free Hosting Support</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Unlimited Images & Videos Upload</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Free SSL Certificates</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>10 Free Email Id</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>SEO Friendly Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>100% Responsive Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Live Chat Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Payment Gateway Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Social Media Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Call Button Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>WhatsApp Button Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Inquiry Form</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Woocommece Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year 24/7 Free Support For Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Annual Renewal Rs.4000</span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* second list end here */}
                <div className="footer_image">
                  <div className="click_now"
                    onClick={formHandler}
                   >
                    <a >
                      <span className="click_now_butn">Click Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* third card end here */}
            {/* fourth card start here */}
            <div className="fourth_card">
              <div className="one_cards">
                {/* card button start here */}
                <div className="card_button">
                  <button className="card_large_butn">
                    Premium Design E-commerce 
                     <span>Plan</span>
                  </button>
                </div>
                {/* card button end here */}
                <div className="card_image">
                  <img src={ArtboardFour} alt="card_image" draggable="false" />
                </div>
                <div className="card_ul_list_one">
                  <ul className="card_list">
                    <li className="sublist_one">
                      <small>
                        <strike>₹ 25,000</strike>
                      </small>
                      <b style={{ color: "#151AB2"  }}>
                      ₹ 19,999
                      </b>
                    </li>
                    <li className="sublist_one">
                      <h5>( + 18% GST ₹ 3,600 )</h5>
                    </li>
                    <li className="sublist_one">
                      <h5>Features Includes</h5>
                    </li>
                  </ul>
                </div>
                {/* second list start here */}
                <div className="card_second_ul_list">
                  <div className="second_ul_list">
                    <ul className="second_list">
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>30 pages Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Domain Name ( .com .in .org )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Hosting ( Unlimited Space )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>20 Product Categories</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>30 Product Listing From Our Side</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Premium Design</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Lifetime 24/7 Free Hosting Support</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Dynamic Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Admin Access​</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Google Search Console Setup</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>SEO Friendly Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Lifetime 24/7 Free Hosting Support</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Unlimited Images & Videos Upload</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Free SSL Certificates</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>10 Free Email Id</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Premium Theme</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>SEO Friendly Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>100% Responsive Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Live Chat Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Payment Gateway Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Social Media Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Call Button Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>WhatsApp Button Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Inquiry Form</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Ecommece Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Product Variation Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Auto Invoice Bill Generater Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Wallet System Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Order Notification Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>OTP Verification Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Technical Support For Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Annual Renewal For Hosting Rs.4000.</span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* second list end here */}
                <div className="footer_image">
                  <div className="click_now" 
                  style={{marginBottom:"6rem"}}
                  onClick={formHandler}
                  >
                    <a >
                      <span className="click_now_butn">Click Now</span>
                    </a>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
            {/*fourth card end here */}
            {/* fiveth card start here */}
            <div className="fiveth_card two_card"
            >
              <div className="one_cards">
                {/* card button start here */}
                <div className="card_button">
                <button className="card_large_butn">
                    Multi-vendor Business & E-
                     <span>commerce Plan</span>
                  </button>
                </div>
                {/* card button end here */}
                <div className="card_image">
                  <img src={ArtboardFiveth} alt="card_image" draggable="false" />
                </div>
                <div className="card_ul_list_one">
                  <ul className="card_list">
                    <li className="sublist_one">
                      <small>
                        <strike>₹ 60,000</strike>
                      </small>
                      <b style={{ color: "var(--sec-first-list-cl)" }}>
                      ₹ 50,000
                      </b>
                    </li>
                    <li className="sublist_one">
                      <h5>( + 18% GST ₹ 9,000 )</h5>
                    </li>
                    <li className="sublist_one">
                      <h5>Features Includes</h5>
                    </li>
                  </ul>
                </div>
                {/* second list start here */}
                <div className="card_second_ul_list">
                  <div className="second_ul_list">
                    <ul className="second_list">
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>40 pages Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Domain Name ( .com .in .org )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Hosting ( Unlimited Space )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Dynamic Website ( Premium Design )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>50 Products Categories</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>30 Products Listing from our side</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Premium Design</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Dynamic Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Admin Access​</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Google Search Console Setup</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Dynamic Multi-Vendor Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Lifetime 24/7 Free Hosting Support</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Unlimited Images & Videos Upload</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Free SSL Certificates</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>20 Free Email Id</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>SEO Friendly Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>100% Responsive Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Live Chat Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Payment Gateway Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Social Media Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Call Button Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>WhatsApp Button Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Inquiry Form</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Multi-Level Ecommece Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Product Variation Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Auto Invoice Bill Generater Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Wallet System Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Order Notification Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>OTP Verification Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Technical Support For Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Annual Renewal For Hosting Rs.4000.</span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* second list end here */}
                <div className="footer_image">
                  <div className="click_now" onClick={formHandler}>
                    <a >
                      <span className="click_now_butn">Click Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* fiveth card end here */}
            {/* sixth card start here */}
            <div className="sixth_card">
              <div className="one_cards"
              
               >
                {/* card button start here */}
                <div className="card_button">
                <button className="card_large_butn">
                    Custom Multi-vendor Business
                     <span>& E-commerce Plan</span>
                  </button>
                </div>
                {/* card button end here */}
                <div className="card_image">
                  <img src={ArtboardFiveth} alt="card_image" draggable="false" />
                </div>
                <div className="card_ul_list_one">
                  <ul className="card_list">
                    <li className="sublist_one">
                      <small>
                        <strike>₹ ???</strike>
                      </small>
                      <b style={{ color: "var(--sec-first-list-cl)" }}>
                      ₹ ???
                      </b>
                    </li>
                    <li className="sublist_one">
                      <h5>( + 18% GST Applicable )</h5>
                    </li>
                    <li className="sublist_one">
                      <h5>Features Includes</h5>
                    </li>
                  </ul>
                </div>
                {/* second list start here */}
                <div className="card_second_ul_list">
                  <div className="second_ul_list">
                    <ul className="second_list">
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Pages: According to the requirements</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Domain Name ( .com .in .org )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Hosting ( Unlimited Space )</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>?? Products Categories</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>?? Products Listing from our side</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Premium Design</span>
                      </li>
                      {/* <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Premium Design</span>
                      </li> */}
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Dynamic Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Admin Access​</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Google Search Console Setup</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Dynamic Multi-Vendor Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Lifetime 24/7 Free Hosting Support</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Unlimited Images & Videos Upload</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Free SSL Certificates</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>20 Free Email Id</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>SEO Friendly Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>100% Responsive Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Live Chat Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Payment Gateway Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Social Media Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Call Button Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>WhatsApp Button Integration</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Inquiry Form</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Multi-Level Ecommece Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Product Variation Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Auto Invoice Bill Generater Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Wallet System Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Order Notification Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>OTP Verification Features</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>1 Year Free Technical Support For Website</span>
                      </li>
                      <li className="second_sublist">
                        <span>
                          <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span>Annual Renewal For Hosting Rs.4000.</span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* second list end here */}
                <div className="footer_image">
                  <div className="click_now" onClick={formHandler}>
                    <a >
                      <span className="click_now_butn">Click Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* sixth card end here */}
          </div>
          {/* main container end here */}
        </div>
      </div>
    </>
  );
};

export default Card;
