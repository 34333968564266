import React, { useEffect } from "react";
import NavbarStyle from "../../assets/style/dwsitlandingpage/navbarStyle";
// import Form from "./Form";
import { Link } from "react-scroll";

const Navbar = () => {
  const formHandler = () => {
    const showHide = document.querySelector(".main_form_container");
    console.log("=>", showHide);
    showHide.style.display = "block";
  };

  // const scrollHandler = (clickLink) => {
  //   if (clickLink === "location") {
  //     window.scrollTo({ top: 1122, behavior: "smooth" });
  //   } else if (clickLink === "ourServices") {
  //     window.scrollTo({ top: 2346, behavior: "smooth" });
  //   } else if (clickLink === "package") {
  //     window.scrollTo({ top: 3200, behavior: "smooth" });
  //   }
  // };
  // window.onscroll = function () {
  //   console.log("scrolled=>", window.scrollY);
  // };
  return (
    <>
      <NavbarStyle />
      <nav class="navbar navbar-fixed-top navbar-expand-lg navbar-light ">
        <div class="container">
          <a class="navbar-brand" href="#">
            <img
              src="https://www.dwsit.com/images/logo.png"
              alt="dwsit_logo"
              style={{ maxWidth: "100%" }}
            />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <Link
                  class="nav-link active"
                  to="homeOffice"
                  smooth={true}
                  duration={500}
                >
                  LOCATION
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class="nav-link"
                  to="homeServiceCard"
                  smooth={true}
                  duration={500}
                >
                  OUR SERVICES
                </Link>
              </li>

              <li class="nav-item">
                <Link
                  class="nav-link"
                  to="homeourcaseStudies"
                  smooth={true}
                  duration={500}
                >
                  OUR CASE STUDIES
                </Link>
              </li>
            </ul>
            <button class="btn" onClick={formHandler}>
              Get Exact Quotation
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
