import { createGlobalStyle } from "styled-components";

const FormStyle = createGlobalStyle`
*,*::after,*::before{
 padding:0;
 margin:0;
 box-sizing: border-box;
}
:root{
    --image-bg:rgba(100,84,156,1);
    --font-color:#fff;
    --form-btn-bg:#fff;
    --font-family: "Montserrat",sans-serif;
    --font-weight-one:600;
    --font-weight-two:500;
    --font-weight-three:400;
    --font-weight-four:300;
    --ok-butn-bg: #002037;
    --form-top-border:rgb(208, 203, 225);
    --second-border-cl:rgb(100, 83, 156);
    --image-bg:#002037;
    --border-overlap-cl:#002037;
    --blue-border:blue;
    --nine-span-cl:rgba(0, 0, 0, 0.7);
}

html,body{
    font-size:62.5%;
    font-family: var(--font-family);
}

.main_form_image{
    display:grid;
    grid-template-columns: repeat(2,max-content);
    place-content: center;
    place-items: center;
    width:100%;
    .form_text_butn{
        background:var(--form-btn-bg);
        width:55rem;
        height:47rem;
        display: grid;
        border-bottom-left-radius: 1rem;
        border-top-left-radius: 0.5rem; 
        border-top: 4px solid var(--form-top-border);
        position:relative;
        z-index: 1;
    }
    .form_text_butn::before{
        content:"";
        position:absolute;
        width:${(props) => props.borderWidth}px;
        left:0;
        top:-0.4rem;
        border:2px solid var(--image-bg);
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        padding-left: 29rem;
    }
    .form_image_one{
        padding-left: 7rem;
        padding-top: 7rem;
        transition:all 0.5s ease-in-out;
        height: 47rem ;
        transform:translate(0,${(props) => props.translateValue}%);
        /* transform:translate(0,-200%); */
        /* .center{
            display: grid;
            place-content: center;
        } */
        /* submit button start here */
        .submit_button{
            display: flex;
            flex-direction: column;
            span{
                margin-top:1rem; 
                font-size:1.5rem;
                color:red;
                font-weight: 600;
            }

        }
        /* submit button end here */
        .form_text{
            /* display:flex;
            column-gap:1rem; */
            .thankYou_Image{
                max-width:10rem;
                min-width:5rem;
                img{
                    width:100%;
                }
            }
            /* thankyou heading */
            .thanyou_heading{
                padding-left: 2%;
                 padding-right: 2%;
                 text-align:center;
                 text-transform: capitalize;
            }
            /* thankyou heading */
            .number_span{
                background: var(--image-bg);
                border-radius: 50%;
                width:2.5rem;
                height: 2.5rem;
                margin-bottom:0.5rem;
                display: grid;
                place-items: center;
                color:#fff;
            }
            span{
             font-size:1.5rem;
             font-weight:var(--font-weight-two);
             font-family:var(--font-family);
             display: flex;
             align-items: center;
             h2{
                font-size: 2.4rem;
                display: flex;
                flex-direction: column;
                font-weight: var(--font-weight-two);
                font-family: var(--font-family);
                line-height: 3rem;
                /* text-align: center; */
                padding-top:1rem;
                span{
                    font-size:1.8rem;
                    color: var(--color);
                    font-weight: var(--font-weight-two);
                }
             }
             .form_nine,
             .form_ten,
             .form_eleven,
             .form_twelve{
                span{
                    font-size:1.65rem;
                    font-weight:450;
                    color:var(--nine-span-cl);
                }
            }
            }
        }
        /* form_butn_one start here */
        .form_butn{
            margin-top: 2rem;
            .form_butn_one,.form_butn_two{
            display: flex;
            padding-left:0.5rem;
            padding-right:0.5rem;
            align-items: center;
            background: rgb(229, 229, 229);
            border: 1px solid #5b5b5b;
            border-radius: 0.5rem;
            width: 25rem;
            height: 4rem;
            margin-bottom:1rem;
            column-gap: 1rem;
            cursor: pointer;
            /* alphabat start here */
             .alphabat{
                
                span{

                font-weight:var(--font-weight-two);
                font-size:1.8rem;
                white-space: nowrap;
                border:1px solid #666666;
                padding:0.5rem 0.8rem;
                font-size:1.1rem;
                
            }
             }
            /* alphabat end here */
           
         /* form text check start here */
         .form_text_check{
            width: 20rem;
            h5{
              font-size:1.5rem;
              font-weight: var(--font-weight-two);
              margin-top: 0.5rem;
              white-space: nowrap;
              display:grid;
              grid-template-columns:repeat(2,max-content);
              column-gap:0.8rem;
            }
         }
         /* form text check end here */

        }
        /* id of second form start here */
        #number_second_butn_A,
        #number_second_butn_B{
            width:27rem;
        }
        /* id of second form end here */
        }
        .form_Input{
             display: flex;
             flex-direction: column;
             width:26rem;
             padding-top:2rem;
            input{
                border: none;
                border-bottom: 1px solid black;
                font-size: 1.5rem;
                outline: none;
                margin-bottom: 1.8rem;
            }
            #numberPage,
            #domainName,
            #companyName,
            #firstName,
            #lastName,
            #companyRole,
            #city,
            #email,
            #secondEmail{
                font-size: 1.2rem;
                font-weight: 600;
                color: red;
                margin-top: -1rem;
                margin-bottom: 1rem;
            }
            .ok_btn_press_enter{
                display:flex;
                align-items: center;
                column-gap: 1rem;
                .press_enter{
                   
              span{
                font-size:1.5rem;
              }
            }
            }
        }
        /* form_butn_one end here */
    }
    /* image section start here */
    .Image {
        background: var(--image-bg);
        /* background:red; */
        width: 55rem;
        height: 47rem;
        display: grid;
        place-items: center;
        overflow: hidden;
        border-bottom-right-radius: 1rem;
        border-top-right-radius: 1rem;
        position:relative;
        .imgs{
            transform:translate(0,${(props) => props.translateImage}%);
            height: 35rem;
            /* background: blue; */
            /* width: max-content; */
            margin-top:12rem;
            transition:all 0.5s ease-in-out;
        }
        .image_one,
        .image_two,
        .image_three,
        .image_four,
        .image_five,
        .image_six,
        .image_seven,
        .image_eight,
        .image_nine,
        .image_ten,
        .image_eleven{
                .logo_image{
                    max-width: 5rem;
                    min-width: 4rem;
                    height: 5rem;
                    margin: 0 auto;
                    margin-bottom: 0.5rem;
                   img{
                    width:100%;
                    height: 100%;
                   }
                }
                .image_heading{
                    
                     h2{
                        color:var(--font-color);
                        font-family:var(--font-family);
                        text-transform: capitalize;
                        font-size:1.8rem;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        text-transform: capitalize;
                     }
                     p{
                        color:var(--font-color);
                        font-size:1.2rem;
                        text-align: center;
                     }
                     img{
                       width: 10rem;
                     }
                }
                .company_logo_image{
                    max-width: 40rem;
                    min-width: 10rem;
                    /* height: 5rem; */
                    margin: 0 auto;
                    margin-top: 0.5rem;

                    img{
                       width:100%;
                       height: 100%;
                    }
                }
            img{
                width:40rem;
                height: 30rem;
                transition: all 0.5s ease-in-out;
            }
        }
    }
    /* image section end here  */
    /* ok button start here */
    .ok_butn{
        background:var(--ok-butn-bg);
        width:6.5rem;
        height:4rem;
        display: grid;
        place-content: center;
        border-radius:0.5rem;
        margin-top: 1.5rem;
        cursor: pointer;
        span{
            font-size:1.8rem;
            font-weight:var(--font-weight-one);
            color:var(--font-color);
            
        }
    }
    /* ok button end here */
    /* active toggle effect start here */
    .no-active{
        display: none;
    }
    .active{
        display:block;
    }
    .border{
        border:2px solid red;
    }
    /* active toggle effect end here */

    /* span button five error start here */
    .one_butn_error,
    .two_butn_error,
    .five_butn_error{
        font-size: 1.2rem;
        font-weight: 600;
        color: red;
        margin-top: -1rem;
        margin-bottom: 1rem;          
            }
    /* span button five error end here */
  /* cross butn start here */
  .xmark_div{
    position: absolute;
    top:0;
    right:0;
    margin-top:2rem;
    margin-right: 2rem;
    i{
        font-size:1.8rem;
        color:#fff;
        cursor: pointer;
    }
 }
  /* cross butn end here  */
}
/* span codes start here */

/* span codes end here */
/* media query start here for 992px */

@media (max-width:1024px){
    .main_form_image{
        grid-template-columns: repeat(1,max-content);
        transform:rotate(180deg);
        /* form text butn start here */
        /* form image nine , ten , eleven, twelve  start here*/
        .form_image_nine,
        .form_image_ten,
        .form_image_eleven,
        .form_image_twelve{
           padding-left:2rem;
           border-radius: 0.5rem;
        }
        /* form image nine, ten,elven,twelve end here */
        /* form_image_one start here */
        /* .form_image_one{
            padding-left:0;
        } */
        /* form_image_one end here */
        .form_text_butn{
            transform: rotate(180deg);
            border-top:none;
            border-top-left-radius: 0;
            width: 48rem !important;
            border-bottom-right-radius:0.5rem;
            &::before{
                border:none;
            }
        }
        /* form text butn end here */
        /* image section start here */
        .Image{
            transform:rotate(180deg);
            border-top-right-radius: 1rem;
            border-bottom-right-radius:0;
            border-top-left-radius:1rem;
            width:48rem;
        }
        /* image section end here */
   
    }
}
 @media (max-width:999px){
    .main_form_image{
        grid-template-columns: repeat(1,max-content);
        transform:rotate(180deg);
        /* form text butn start here */
        /* form image nine , ten , eleven, twelve  start here*/
        .form_image_nine,
        .form_image_ten,
        .form_image_eleven,
        .form_image_twelve{
           padding-left:2rem;
           border-radius: 0.5rem;
        }
        /* form image nine, ten,elven,twelve end here */
        /* form_image_one start here */
        /* .form_image_one{
            padding-left:0;
        } */
        /* form_image_one end here */
        .form_text_butn{
            transform: rotate(180deg);
            border-top:none;
            border-top-left-radius: 0;
            width: 48rem !important;
            &::before{
                border:none;
            }
        }
        /* form text butn end here */
        /* image section start here */
        .Image{
            transform:rotate(180deg);
            border-top-right-radius: 1rem;
            border-bottom-right-radius:0;
            border-top-left-radius:1rem;
            width:48rem;
        }
        /* image section end here */
        .form_image_one{
            .form_text {
                span{
                    h2{
                        font-size:1.4rem;
                    }
                }
            }
            .form_Input {
                padding-top: 0rem;
        }
    }
}
 }
@media (max-width:640px){
    .main_form_image{
        .form_text_butn{
            width: 38rem !important;
        }
        .form_image_one{
            /* padding-left:5rem; */
            .form_text {
                span{
                    h2{
                        font-size:1.5rem;
                    }
                }
            }
            /* input start here */
            /* .form_Input {
                padding-top: 0rem;
                input{
                    font-size: 2rem;
                }
            } */
            /* input end here */
        }
       
        /* form text butn end here */
        /* image section start here */
        .Image{
            width:38rem;
            /* image width start here */
             .image_one img, .image_two img, .image_three img, .image_four img, .image_five img,  .image_six img, .image_seven img, .image_eight img, .image_eleven img{
                width:30rem;
                height:26rem;
                margin-top: 7rem;
            }
            /* image width end here */
        }
        /* image section end here */
    }
}
@media (max-width:480px){
    html,body{
      font-size:48% !important;
    
}
    .main_form_image{
        
        .form_text_butn{
            width: 30rem !important;
           height: 33rem;
        }
    
        .form_image_one{
            padding-left:1.5rem !important;
            padding-top:1rem !important;
            .form_butn{
                margin-top:0.5rem;
                .form_butn_one,
                .form_butn_two{
                    margin-bottom:0.5rem;
                }
            }
        }
        .form_Input{
                    width:26rem;
            }
        /* form text butn end here */
        /* image section start here */
        .Image{
            width:30rem;
            height:30rem;
            .imgs{
                margin-top:10rem;
            }
            .image_one img, 
            .image_two img, 
            .image_three img, 
            .image_four img, 
            .image_five img,  
            .image_six img, 
            .image_seven img, 
            .image_eight img, 
            .image_eleven img{
                width: 25rem;
                height: 20rem;
                margin-top: 0;
            }
        }
        /* image section end here */
        /* ok butn start here */
        .ok_butn{
            margin-top:0.5rem;
        }
        /* ok butn end here */
    }
}
@media (max-width:360px){
    html,body{
      font-size:48% !important;
    
}
    .main_form_image{
        
        .form_text_butn{
            width: 30rem !important;
           /* height: 30rem; */
        }
    
        .form_image_one{
            padding-left:1.5rem !important;
            padding-top:1rem !important;
            .form_butn{
                margin-top:0.5rem;
                .form_butn_one,
                .form_butn_two{
                    margin-bottom:0.5rem;
                }
            }
        }
        .form_Input{
                    width:26rem;
            }
        /* form text butn end here */
        /* image section start here */
        .Image{
            width:30rem;
            height:30rem;
            .imgs{
                margin-top:10rem;
            }
            .image_one img, 
            .image_two img, 
            .image_three img, 
            .image_four img, 
            .image_five img,  
            .image_six img, 
            .image_seven img, 
            .image_eight img, 
            .image_eleven img{
                width: 25rem;
                height: 20rem;
                margin-top: 0;
            }
        }
        /* image section end here */
        /* ok butn start here */
        .ok_butn{
            margin-top:0.5rem;
        }
        /* ok butn end here */
    }
}
/* media query end here for 992px */
`;
export default FormStyle;
