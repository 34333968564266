import styled, { createGlobalStyle } from "styled-components";
const Popstyle = createGlobalStyle`
:root{
    --font-family:  "Montserrat", sans-serif;
}
html,body{
    font-size:62.5%;
    font-family:var(--font-family);
}

`
export default Popstyle;