import React from "react";
import arrowLeft from "../../assets/image/digitalmarketing/customer/arrow-left-gold.png";
import arrowRight from "../../assets/image/digitalmarketing/customer/arrow-right-gold.png";
import google from "../../assets/image/digitalmarketing/customer/google.png";
import webDevelopment from "../../assets/image/digitalmarketing/customer/webDevelopment.png";
import topFirms from "../../assets/image/digitalmarketing/customer/top-firms.png";
import trustpilot from "../../assets/image/digitalmarketing/customer/trustpilot.png";
import CustomerStyle from "../../assets/style/digitalmarketing/customer.js";
// import Form from "./Form";
const Customer = () => {
  const formHandler = () => {
    const showHide = document.querySelector(".main_form_container");
    console.log("=>", showHide);
    showHide.style.display = "block";
  };
 
  window.onclick = function (event){
    const formContainer = document.querySelector('.main_form_container');
    if(event.target === formContainer){
      formContainer.style.display = 'none';
    }
  }
  return (
    <>
      <CustomerStyle />
      <div className="main_customer">
        <div className="second_customer">
          {/* customer start here  */}
          <div className="customer">
            <div className="customer_image">
              <div className="customer_image_one">
                <img src={webDevelopment} alt="webDevelopment" />
              </div>
              <div className="customer_image_two">
                <img src={google} alt="google" />
              </div>
              <div className="customer_image_three">
                <img src={topFirms} alt="topFirms" />
              </div>
              <div className="customer_image_four">
                <img src={trustpilot} alt="trustpilot" />
              </div>
            </div>
          </div>
          {/*  customer end here */}
          {/* customer heading start here */}
          <div className="customer_heading">
            <h1>Join 500+ happy customers from India.</h1>
            <p>Get your business website today!</p>
          </div>
          {/* customer heading end here */}
          {/* customer button start here */}
          <div className="customer_form_image_button">
            <div className="customer_form_Images">
              <div className="customer_image_one">
                <img src={arrowLeft} alt="arrowLeft" />
              </div>
              <div className="customer_button">
              <button class="btn" onClick={formHandler}>
                Get Exact Quotation
              </button>
              </div>
               {/* form start here */}
              
            {/* form end here */}
              <div className="customer_image_two">
                <img src={arrowRight} alt="arrowRight" />
              </div>
            </div>
          </div>
          {/* customer buttoin end here */}
        </div>
      </div>
    </>
  );
};

export default Customer;
