import React from "react";
import ModelImages from "../../assets/image/ecomerce/industries/ModelImages.webp";
import IndustriesStyle from "../../assets/style/ecomerce/industries.js";
import Industriesbg from "../../assets/image/ecomerce/industries/Industriesbg.png"
const Industries = () => {
  return (
    <>
    <IndustriesStyle Industries={Industriesbg}/>
      <div className="container-fluid">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="main_industries">
            <div className="second_industries">
              <div className="industries">
                {/* industries heading  */}
                <div className="industries_heading_para">
                  <div className="industries_heading">
                    <h2>
                      Industries We  <span>Serve</span>
                    </h2>
                  </div>
                  <div className="industries_para">
                    <span>
                      We pride ourselves on our ability to cater to a diverse
                      range of industries, providing customized web development
                      services tailored to meet each client's unique needs and
                      objectives.
                    </span>
                  </div>
                </div>
                {/* industries */}
                {/* industries logo and photo */}
                 <div className="industries_logo_image">
                    <div className="industries_logo">
                        <div className="industries_logo_one">
                           <ul className="industries_logo_list_one">
                                <li className="insdustries_sublist_one">
                                    <div className="logo_one">
                                    <i class="fa-light fa-cart-shopping"></i>
                                    </div>
                                    <div className="logo_one_heading">
                                      <h4>E-commerce</h4>
                                    </div>
                                </li>
                                <li className="insdustries_sublist_one">
                                    <div className="logo_one">
                                    <i class="fa-light  fa-laptop-mobile "></i>
                                    </div>
                                    <div className="logo_one_heading">
                                      <h4>Technology &amp; Software</h4>
                                    </div>
                                </li>
                                <li className="insdustries_sublist_one">
                                    <div className="logo_one">
                                   <i class="fa-light fa-notes-medical"></i>
                                    </div>
                                    <div className="logo_one_heading">
                                      <h4>Healthcare &amp; Pharmaceuticals</h4>
                                    </div>
                                    
                                </li>
                           </ul>
                        </div>
                        <div className="industries_logo_two">
                           <ul className="industries_logo_list_two">
                                <li className="insdustries_sublist_two">
                                    <div className="logo_two">
                                    <i class="fa-light fa-building"></i>
                                    </div>
                                    <div className="logo_two_heading">
                                      <h4>Real Estate</h4>
                                    </div>
                                </li>
                                <li className="insdustries_sublist_two">
                                    <div className="logo_two">
                                    <i class="fa-light fa-car"></i> 
                                    </div>
                                    <div className="logo_two_heading">
                                     <h4>Travel &amp; Hospitality</h4>
                                    </div>
                                </li>
                                <li className="insdustries_sublist_two">
                                    <div className="logo_two">
                                    <i class="fa-light fa-school"></i>
                                    </div>
                                    <div className="logo_two_heading">
                                     <h4>Education &amp; E-learning</h4>
                                    </div>
                                </li>
                           </ul>
                        </div>
                        <div className="industries_logo_three">
                            <ul className="industries_logo_list_three">
                                <li className="insdustries_sublist_three">
                                    <div className="logo_three">
                                    <i class="fa-light fa-shirt"></i>
                                    </div>
                                    <div className="logo_three_heading">
                                     <h4>
                                      Fashion &amp;
                                      <span>Apparel</span>
                                     </h4>
                                    </div>
                                </li>
                                <li className="insdustries_sublist_three">
                                    <div className="logo_three">
                                    <i class="fa-light fa-medal"></i>
                                    </div>
                                    <div className="logo_three_heading">
                                      <h4>
                                         Fitness &amp;
                                       <span>Wellness</span>
                                      </h4>
                                    </div>
                                </li>
                                <li className="insdustries_sublist_three">
                                    <div className="logo_three">
                                    <i class="fa-brands  fa-creative-commons-sampling-plus"></i>
                                    </div>
                                    <div className="logo_three_heading">
                                      <h4>
                                        Home &amp; 
                                        <span>Interior</span>
                                        <span> Design</span>
                                      </h4>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* industries image start here */}
                    <div className="industries_image">
                        <img src={ModelImages} alt="ModelImages" />
                    </div>
                    {/* industries image end here */}
                 </div>
                {/* industries logo and photo */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Industries;
