import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Popstyle from '../../assets/style/ecomerce/pop.js';
const slideUp = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const PopContainer = styled.div`
  background: rgb(255, 255, 255);
  border: 2px solid transparent;
  border-radius: 50px;
  bottom: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 15px 2px;
  cursor: pointer;
  display: flex;
  font-size: 100%;
  left: 25px;
  line-height: 18px;
  margin: 0px;
  max-width: 350px;
  min-height: 74px;
  min-width: 300px;
  padding: 0px;
  position: fixed;
  text-decoration: none;
  transition: transform 0.4s, opacity 0.4s;
  z-index: 99999;
  transform: translateY(100px);
  opacity: 0;

  &.visible {
    animation: ${slideUp} 0.4s forwards;
  }

  @media (max-width: 560px) {
    left: 10px;
    min-width: 280px;
  }

  @media (max-width: 480px) {
    left: 5px;
    min-width: 260px;
  }

  @media (max-width: 360px) {
    left: 2px;
    min-width: 240px;
  }
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0;
  width: 70px;
`;

const WidgetImage = styled.img`
  border: 1px solid #fff;
  border-radius: 100%;
  height: 70px;
  margin: 0;
  max-width: inherit;
  width: 70px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 8px 10px;
  width: 100%;
`;

const Title = styled.div`
  color: #41495b;
  font-family:"Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  max-width: 250px;
  overflow: hidden;
  padding-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 560px) {
    font-size: 13px;
    max-width: 220px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    max-width: 200px;
  }

  @media (max-width: 360px) {
    font-size: 11px;
    max-width: 180px;
  }
`;

const VisitorNumber = styled.span`
  color: #41495b;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 560px) {
    font-size: 13px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }

  @media (max-width: 360px) {
    font-size: 11px;
  }
`;

const Body = styled.div`
  color: #8993aa;
  font-family:"Montserrat", sans-serif;
  font-size: 12px;
  margin: 0;
  max-width: 250px;
  padding-right: 10px;

  @media (max-width: 560px) {
    font-size: 11px;
    max-width: 220px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    max-width: 200px;
  }

  @media (max-width: 360px) {
    font-size: 9px;
    max-width: 180px;
  }
`;

const Footer = styled.div`
  align-items: center;
  display: flex;
  margin: 2px 0 0 0;
`;

const PoweredBy = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 12px;
  color: #b0bbd7;
  display: flex;
  font-family:"Montserrat", sans-serif;
  font-size: 10px;
  margin: 0;
  padding: 0 5px;

  @media (max-width: 560px) {
    font-size: 9px;
  }

  @media (max-width: 480px) {
    font-size: 8px;
  }

  @media (max-width: 360px) {
    font-size: 7px;
  }
`;

const PoweredByImage = styled.img`
  height: 13px;
  margin: 0 3px 0 0;
  min-width: inherit;
  width: 12px;

  @media (max-width: 560px) {
    height: 12px;
    width: 11px;
  }

  @media (max-width: 480px) {
    height: 11px;
    width: 10px;
  }

  @media (max-width: 360px) {
    height: 10px;
    width: 9px;
  }
`;

const NotificationClose = styled.a`
  color: gray;
  font-weight: bold;
  position: absolute;
  right: -5px;
  text-decoration: none;
  top: -7px;

  svg {
    fill: #bfc9e0;
  }
`;

const Pop = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const showPop = setInterval(() => {
      setVisible(true);
      setTimeout(() => setVisible(false), 2000);
    }, 5000);

    return () => clearInterval(showPop);
  }, []);

  return (
<>
<Popstyle/>
    <PopContainer className={visible ? 'visible' : ''}>
      <ImageWrapper>
        <WidgetImage
          src="https://a.trstplse.com/icons/on-fire.png"
          alt="Recent Activity"
        />
      </ImageWrapper>
      <Content>
        <Title>
          <VisitorNumber>581</VisitorNumber> people in the last 7 days
        </Title>
        <Body>Requested for Estimate</Body>
        <Footer>
          <PoweredBy>
            <PoweredByImage
              src="https://a.trstplse.com/icon-tiny.png"
              alt="Verified by TrustPulse"
            />
            <a
              href="https://trustpulse.com/?utm_source=widget&amp;utm_medium=link&amp;utm_campaign=verified"
              target="_blank"
              rel="noopener noreferrer"
              data-faitracker-click-bind="true"
            >
              by <span>TrustPulse</span>
            </a>
          </PoweredBy>
        </Footer>
        <NotificationClose
          href="#"
          title="Close TrustPulse notification"
          data-faitracker-click-bind="true"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z" />
          </svg>
        </NotificationClose>
      </Content>
    </PopContainer>
</>
  );
};

export default Pop;
