import { createGlobalStyle } from "styled-components";

const CompanyStyle = createGlobalStyle`
*,*::after,*::before{
    box-sizing:border-box;
    padding:0;
    margin:0;
}
:root{
    --font-family:"Montserrat", sans-serif;
    --brand-image-bg:#fff;
    --box-shadow:5px 6px 7px -4px rgba(0, 0, 0, 0.25);
    --box-hover-shadow:-1px 7px 25px -4px rgba(0,0,0,0.5);
   /* --webkit-box-shadow:-webkit-box-shadow: -1px 7px 25px -4px rgba(0,0,0,0.25); */
   --heading-weight:800;
   --border-left-cl:#e9ecef;
   --logo-bg:#fff;
}
html,body{
    font-size:62.5%;
    font-family:var(--font-family),sans-serif;
}
.second_websitename_div{
    background:#0070cc;
    margin-bottom: 7rem;
}
.website_div{
    display: grid;
    place-content: center;
    grid-template-columns: repeat(1,max-content);
    column-gap: 11rem;
    margin-top: 5rem;

    /* heading start here */

    /* .technology_partner,
    .hosting_partner{
        position:relative;
        &::after{
            content: "";
            position: absolute;
            border-left: 1px solid var(--border-left-cl);
            height: 21rem;
            top: 0;
            right: -5rem;
        }
    } */

    .technology_partner{
        margin-top:4rem;
        .heading{
            font-size:2rem;
            font-family: var(--font-family);
            font-weight:var(--heading-weight);
            position:relative;
            text-decoration: underline;
            text-decoration-color: #fff;
            text-align: center;
            h1{
               font-weight: 600;
               color:#fff;
            }
        }
    }
    /* heading end here */

    .company_name{
        margin-top: 5rem;
        display: grid;
        grid-template-columns: repeat(2,max-content);
        column-gap: 5rem;
        row-gap: 2rem;
        margin-bottom: 10rem;
        /* heading section start here */
        .front_hand_heading,
        .back_hand_heading,
        .frame_work_heading,
        .ecommerce_heading{
            text-align: center;
            h2{
                font-size:2rem;
                font-weight: 600;
                font-family:var(--font-family),sans-serif;
                margin-bottom: 1rem;
                color:#fff;
            }
        }
        /* heading section end here */
       /* front hand start here */
        .frontHand_image{
            display: grid;
            place-content: center;
            grid-template-columns: repeat(8,max-content);
            column-gap:0.3rem;
        .image_one,
        .image_two,
        .image_three,
        .image_four,
        .image_five,
        .image_six,
        .image_seven,
        .image_eight{
          
            width: 5rem;
            height: 5rem;
            background-color:var(--logo-bg);
            border-radius:0.5rem;
            box-shadow:var(--box-shadow);
            img{
                width: 100%;
                padding: 1rem;
            }
        }
        }
        /* front hand end here */
        /* backhand start here */
        .backhand_image{
            display: grid;
            place-content: center;
            grid-template-columns: repeat(7,max-content);
            column-gap: 0.3rem;
        .image_one,
        .image_two,
        .image_three,
        .image_four,
        .image_five,
        .image_six,
        .image_seven{
            width: 5rem;
            height: 5rem;
            background-color: var(--logo-bg);
            border-radius:0.5rem;
            box-shadow:var(--box-shadow);
             img{
                width: 100%;
                height: 100%;
                padding: 1rem;
             }
        }
        }
        /* backhand end here */
        /* framework_image start here */
        .framework_image{
            display: grid;
            place-content: center;
            grid-template-columns: repeat(7,max-content);
            column-gap: 0.3rem;
        .image_one,
        .image_two,
        .image_three,
        .image_four,
        .image_five,
        .image_six{
            width: 6rem;
            height: 6rem;
            background-color: var(--logo-bg);
            border-radius:0.5rem;
            box-shadow:var(--box-shadow);
             img{
                width: 100%;
                padding: 0.1rem;
                height: 100%;

             }
        }
        }
        /* framework_image end here */
        /* ecommerce_image start here */
        .ecommerce_image{
            display: grid;
            place-content: center;
            grid-template-columns: repeat(8,max-content);
            column-gap: 0.3rem;
        .image_one,
        .image_two,
        .image_three,
        .image_four,
        .image_five,
        .image_six,
        .image_seven,
        .image_eight{
            width: 5rem;
            height: 5rem;
            background-color: var(--logo-bg);
            border-radius:0.5rem;
            box-shadow:var(--box-shadow);
             img{
                width: 100%;
                padding: 1rem;
                height: 100%;
             }
        }
        }
        /* ecommerce_image end here */
       
    }
}
/* media query start here for 1200px */
@media (max-width:1200px){
  html{
    font-size:60%;
  }
  .website_div{
        column-gap: 2rem;
        .technology_partner,
        .hosting_partner{  
        &::after{
            border-left:none;
        }
    }
    } 
}
/* media query end here for 1200px */

/* media query start here for 1024px */
@media (max-width:1024px){
    html{
    font-size:58%;
}
    .website_div{
        column-gap: 2rem;
    }
}
/* media query end here for 1024px */

/* media query start here for 992px */
@media (max-width:992px){
    .website_div{
        grid-template-columns:repeat(1,max-content);
        column-gap:4rem;
        place-items: center;
        
    /* security_partner start here */
     .security_partner{
        margin-bottom:2rem;
        .heading{
            display:grid;
            place-content: center;
        }
    /* security_partner end here */
    }
    /* company name start here */
    .company_name{
        margin-top:2rem;
        row-gap:2rem;
        grid-template-columns: repeat(1,max-content);
        .frontHand_image,
        .backhand_image,
        .framework_image,
        .ecommerce_image{
            grid-template-columns: repeat(8,max-content);
        }
    }
    /* company name end here */
}
}
/* media query end here for 992px */

/* media query start here */
@media (max-width:600px){
    .website_div{
        grid-template-columns:repeat(1,max-content);
        .company_name{
        grid-template-columns: repeat(1,max-content);
        .frontHand_image,
        .backhand_image,
        .framework_image,
        .ecommerce_image{
            grid-template-columns: repeat(8,max-content);
            row-gap: 1rem;
        }
    }
    }
}

/* media query end here */
`;

export default CompanyStyle;
