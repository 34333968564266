import styled, { createGlobalStyle } from "styled-components";
const ServiceCardStyled = createGlobalStyle`
*,*::after,*::before{
    padding:0;
    margin: 0;
    box-sizing:border-box;
}
:root{
    --font-family: "Archivo";
    --second-font-family: "Montserrat", sans-serif
    --font-six:600;
    --font-five:500;
    --font-four: 400;
    --font-three:300;
    --font-two:200;
    --font-one:100;
    --service-card-bg: #6edbff;
    --box-shadow:5px 6px 7px -4px rgba(0, 0, 0, 0.25) ;

}
html,body{
    font-family:var(--font-family),sans-serif;
    font-size:62.5%;
}
.main_serviceCard{
    background: #0070cc;
    padding-top:3rem;
    padding-bottom:3rem;
}
  .service_section{
    display:grid;
    place-content: center;
    place-items: center;
    
    /* heading start here */
    .ourServices {
        display: grid;
        h2{
            text-align: center;
            font-size: 3.5rem;
            font-family:var(--second-font-family);
            font-weight: 600;
            color:#fff;
        }
       span{
            font-size: 1.8rem;
            font-weight:500;
            color:#fff;
            width:60%;
            margin:3rem auto;
            justify-self: unset;
            text-align: center;
        }
    }
    /* heading end here */
    /* image start here */
    .servce_cards{
        display: grid;
        grid-template-columns: repeat(3,max-content);
        column-gap: 3rem;
        row-gap: 3rem;
        margin-top: 1rem;
        margin-bottom: 8rem;
    .serivce_card_one,
    .serivce_card_two,
    .serivce_card_three,
    .serivce_card_four,
    .serivce_card_five,
    .serivce_card_six{
        background:var(--service-card-bg);
        /* background:red; */
        width:30rem;
        height:25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top:5rem;
        border-radius:0.5rem; 
        row-gap: 2rem;
        box-shadow: var(--box-shadow);
        -webkit-box-shadow: 5px 6px 7px -4px rgba(0, 0, 0, 0.25);
        &:hover{
            box-shadow:-1px 7px 25px -4px rgba(0,0,0,0.25);
            -webkit-box-shadow: -1px 7px 25px -4px rgba(0,0,0,0.25);
        }
        .service_card_image{
            width:8rem;
            img{
               width: 100%;
            }
        }
        .service_card_heading{
            h1{
                font-size:1.8rem;
                font-weight: var(--font-six);
                text-align: center;
                padding-left: 5%;
                padding-right:5%;
            }
            /* p{
                  font-size:1.5rem;
                  font-weight:var(--font-two);
                  color:#fff;
                  text-align: center;
            } */
        }
    }
    }
   
    /* image end here */
  }
  @media (max-width:1024px){
    .service_section {
        .servce_cards{
            grid-template-columns: repeat(2,max-content);
        }
    }
  }
  @media (max-width: 680px){
    .service_section {
        .servce_cards{
            grid-template-columns: repeat(2,max-content);

            .serivce_card_one,
            .serivce_card_two,
            .serivce_card_three,
            .serivce_card_four,
            .serivce_card_five,
            .serivce_card_six{
                    width: 23rem;
                    height: 23rem;
        .service_card_image{
            width:5rem;
            img{
               width: 100%;
            }
        }
        
        .service_card_heading{
            h1{
                font-size:1.5rem;
            }
            p{
                font-size:1.5rem;
            }
        }
    }
     
        }
        .ourServices {
        h2{
            font-size: 3rem;
        }
       
    }
    }


  }


  @media (max-width: 560px){
    .service_section {
        
    .servce_cards{
      grid-template-columns: repeat(1,max-content);
    .serivce_card_one,
    .serivce_card_two,
    .serivce_card_three,
    .serivce_card_four,
    .serivce_card_five,
    .serivce_card_six{
        width: 25rem;
        height: 25rem;
        .service_card_image{
            width:8rem;
            img{
               width: 100%;
            }
        }
        
    }
    }
    .ourServices {
        h2{
            font-size: 2.5rem;
        }
       
    }
    }
  }
`;

export default ServiceCardStyled;
