import React, { useEffect, useState } from "react";
// import formOne from "../../assets/image/ecomerce/form/formImageOne.png";
import formTwo from "../../assets/image/ecomerce/form/formImageTwo.png";
// import formThree from "../../assets/image/ecomerce/form/formImageThree.png";
// import formFour from "../../assets/image/ecomerce/form/formImageFour.png";
import formFive from "../../assets/image/ecomerce/form/formImageFive.png";
// import formSix from "../../assets/image/ecomerce/form/formImageSix.png";
// import formSeven from "../../assets/image/ecomerce/form/formImageSeven.png";
// import formEight from "../../assets/image/ecomerce/form/formImageEight.png";
// import formEleven from "../../assets/image/ecomerce/form/formImageEleven.png";
import dwsitLogo from "../../assets/image/ecomerce/form/dwsitLogo.png";
import FormStyle from "../../assets/style/ecomerce/form";
// import namaste from "../../assets/image/ecomerce/form/namaste.png";
import thankYou from "../../assets/image/ecomerce/form/thank-you.png";
const Form = () => {
  const [borderWidth, setBorderWidth] = useState(100);
  const [translateValue, setTranslateValue] = useState(0);
  const [hasTrue, setHasTrue] = useState(false);
  const [translateImage, setTranslateImage] = useState(0);
  const [fiveTrue, setFiveTrue] = useState(false);
  const [oneTrue, setOneTrue] = useState(false);
  const [twoTrue, setTwoTrue] = useState(false);

  const [windowWidth, setWindowWidth] = useState(110);
  const [inputValues, setInputValues] = useState({
    number_page: "",
    domain_name: "",
    company_name: "",
    first_name: "",
    last_name: "",
    company_role: "",
    city: "",
    email: "",
    secondemail: "",
  });

  const [blur, setBlur] = useState({
    number_page: false,
    domain_name: false,
    company_name: false,
    first_name: false,
    last_name: false,
    company_role: false,
    city: false,
    email: false,
    secondemail: false,
  });

  // pattern start here
  const pageNum_pattern = "^([1-9][0-9]{0,2}|1000)$";
  const domainName =
    "^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$";
  const companyNamePattern = "^[a-zA-Z]+(.[a-zA-Z]{2,})?$";
  const firstNamePattern = "^[A-Za-z]+$";
  const lastNamePattern = "^[A-Za-z]+$";
  const companyRolePattern = "^[A-Za-z]+$";
  const cityPattern = "^[A-Za-z]{2,}$";
  const emailPattern = "^[A-Za-z0-9]{3,}@[A-Za-z]{2,}[.]{1}[A-Za-z.]{3,}$";
  // pattern end here
  // form validation start here

  // error handler start here
  const errorHandler = () => {
    const numberPage_Error = document.querySelector("#numberPage");
    const domainName_Error = document.querySelector("#domainName");
    const companyName_Error = document.querySelector("#companyName");
    const firstName_Error = document.querySelector("#firstName");
    const lastName_Error = document.querySelector("#lastName");
    const companyRole_Error = document.querySelector("#companyRole");
    const city_Error = document.querySelector("#city");
    const email_Error = document.querySelector("#email");
    // const secondEmail_Error = document.querySelector("#secondEmail");

    // Rest of your validation code...
    if (blur.number_page && !inputValues.number_page.match(pageNum_pattern)) {
      numberPage_Error.textContent = `Please enter number of page`;
    } else {
      numberPage_Error.textContent = ``;
    }

    // domain_name start here
    if (blur.domain_name && !inputValues.domain_name.match(domainName)) {
      domainName_Error.textContent = `Please enter your domain name`;
    } else {
      domainName_Error.textContent = ``;
    }

    // company name start here
    if (
      blur.company_name &&
      !inputValues.company_name.match(companyNamePattern)
    ) {
      companyName_Error.textContent = `Please enter your company name`;
    } else {
      companyName_Error.textContent = ``;
    }

    // first name start here
    if (blur.first_name && !inputValues.first_name.match(firstNamePattern)) {
      firstName_Error.textContent = `Please enter your first Name`;
    } else {
      firstName_Error.textContent = ``;
    }

    // last name start here
    if (blur.last_name && !inputValues.last_name.match(lastNamePattern)) {
      lastName_Error.textContent = `Please enter your last Name`;
    } else {
      lastName_Error.textContent = ``;
    }

    // company role start here
    if (
      blur.company_role &&
      !inputValues.company_role.match(companyRolePattern)
    ) {
      companyRole_Error.textContent = `Please enter your company role`;
    } else {
      companyRole_Error.textContent = ``;
    }

    // city start here
    if (blur.city && !inputValues.city.match(cityPattern)) {
      city_Error.textContent = `Please enter your city name`;
    } else {
      city_Error.textContent = ``;
    }

    // email start here
    if (blur.email && !inputValues.email.match(emailPattern)) {
      email_Error.textContent = `Please enter your email`;
    } else {
      email_Error.textContent = ``;
    }

    // secondemail start here
    // if (blur.secondemail && !inputValues.secondemail.match(emailPattern)) {
    //   secondEmail_Error.textContent = `Please enter your official email`;
    // } else {
    //   secondEmail_Error.textContent = ``;
    // }
  };
  // error handler end here
  const changeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputValues({ ...inputValues, [name]: value });
  };

  const blurHandler = (e) => {
    setBlur({ ...blur, [e.target.name]: true });
  };
  // form validation end here
  const toggleActive = (e, buttonType, index) => {
    const checkIcon = document.querySelectorAll(".no-active");
    const formButn = document.querySelectorAll(".button");
    const alphabat = document.querySelectorAll(".alphabat>span");

    setBorderWidth((prevBorderWidth) => {
      if (prevBorderWidth <= 0) {
        return prevBorderWidth + 333.2;
      } else {
        return prevBorderWidth + 333.2;
      }
    });

    checkIcon.forEach((item) => item.classList.remove("active"));
    formButn.forEach((item) => (item.style.border = "1px solid #000"));
    alphabat.forEach((item) => {
      item.style.backgroundColor = "inherit";
      item.style.color = "inherit";
    });

    const currentButton = e.currentTarget;
    const currentCheckIcon = currentButton.querySelector(".no-active");

    if (currentCheckIcon) {
      currentCheckIcon.classList.add("active");
      const currentAlphabetSpan = currentButton.querySelector(".alphabat>span");
      currentAlphabetSpan.style.backgroundColor = "black";
      currentAlphabetSpan.style.color = "white";
    }

    currentButton.style.border = "2px solid #2e2e2e";
  };

  // button error start here
  const butnOneError = () => {
    const one_butn_error = document.querySelector(".one_butn_error");
    if (!oneTrue) {
      one_butn_error.textContent = `plz choose any option`;
    } else {
      one_butn_error.textContent = ``;
    }
  };
  const butnTwoError = () => {
    const two_butn_error = document.querySelector(".two_butn_error");
    if (!twoTrue) {
      two_butn_error.textContent = `plz choose any option`;
    }
  };
  const butnFiveError = () => {
    const five_butn_error = document.querySelector(".five_butn_error");
    if (!fiveTrue) {
      five_butn_error.textContent = `plz choose any option`;
    }
  };
  // button error end here
  const okHandler = () => {
    setTranslateValue((prev) => prev - 100);
    setTranslateImage((prev) => prev - 132);
  };
  useEffect(() => {
    const ImageBg = document.querySelector(".Image");
    const formTextButn = document.querySelector(".form_text_butn")
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains("image_twelve")) {
              ImageBg.style.width = "0";
              formTextButn.style.borderRadius="1rem"
               formTextButn.style.width="110rem"
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    const target = document.querySelector("#image_nine");
    const targetTwo = document.querySelector("#image_eleven");
    const targetThree = document.querySelector("#image_twelve");
    if (target) {
      observer.observe(target);
    }
    if (targetTwo) {
      observer.observe(targetTwo);
    }
    if (targetThree) {
      observer.observe(targetThree);
    }
    return () => observer.disconnect();
  }, []);
  // cross butn start here

  const hideFormHandler = () => {
    const formContainer = document.querySelector(".main_form_container");
    formContainer.style.display = "none";
  };
  //cross butn end here
  return (
    <>
      <FormStyle
        borderWidth={borderWidth}
        translateValue={translateValue}
        translateImage={translateImage}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="main_form_image">
            <div className="form_text_butn">
              <div style={{ overflow: "hidden" }}>
                {/* form_image_one */}
                <div className="form_image_one">
                  <div className="bg" style={{ height: "40rem" }}>
                    {/* form text start here */}
                    <div className="form_text">
                      <span className="number_span">1</span>
                      <span>
                        <h2>
                          What kind of website you are looking for
                          {/* <span>looking for?*</span> */}
                        </h2>
                      </span>
                    </div>
                    {/* form text end here */}
                    {/* form butn start here */}
                    <div className="form_butn">
                      {/* form butn start here */}
                      <div
                        className="form_butn_one  button butn_one"
                        onClick={(e) => toggleActive(e, "one", 0)}
                        onMouseDown={() => setOneTrue(true)}
                      >
                        <div className="alphabat">
                          <span>A</span>
                        </div>
                        <div className="form_text_check ">
                          <h5>
                            Bussiness Website
                            <span className="no-active">
                              <i class="fa-solid fa-check"></i>
                            </span>
                          </h5>
                        </div>
                      </div>
                      {/* form butn one end */}
                      <div
                        className="form_butn_two button butn_one"
                        onClick={(e) => toggleActive(e, "two", 1)}
                        onMouseDown={() => setOneTrue(true)}
                      >
                        <div className="alphabat">
                          <span>B</span>
                        </div>
                        <div className="form_text_check">
                          <h5>
                            E-commerce Website
                            <span className="no-active">
                              <i class="fa-solid fa-check"></i>
                            </span>
                          </h5>
                        </div>
                      </div>
                      <span className="one_butn_error"></span>
                      <div
                        className="ok_butn"
                        onClick={oneTrue ? okHandler : butnOneError}
                      >
                        <span>Next</span>
                      </div>
                    </div>
                    {/* form butn end here */}
                  </div>
                </div>
                {/* form_image_two */}
                <div className="form_image_two form_image_one">
                  <div className="bg" style={{ height: "40rem" }}>
                    {/* form text start here */}
                    <div className="form_text">
                      <span className="number_span">2</span>
                      <span>
                        <h2>
                          What's your goal today
                          {/* <span>looking for?*</span> */}
                        </h2>
                      </span>
                    </div>
                    {/* form text end here */}
                    {/* form butn start here */}
                    <div className="form_butn">
                      {/* form butn start here */}
                      <div
                        className="form_butn_one button butn_two"
                        onClick={(e) => toggleActive(e, "three", 2)}
                        id="number_second_butn_A"
                        onMouseDown={() => setTwoTrue(true)}
                      >
                        <div className="alphabat">
                          <span>A</span>
                        </div>
                        <div className="form_text_check">
                          <h5 style={{ width: "20rem" }}>
                            Redesign existing website
                            <span className="no-active">
                              <i class="fa-solid fa-check"></i>
                            </span>
                          </h5>
                        </div>
                      </div>
                      {/* form butn one end */}
                      <div
                        className="form_butn_two button butn_two"
                        onClick={(e) => toggleActive(e, "four", 3)}
                        id="number_second_butn_B"
                        onMouseDown={() => setTwoTrue(true)}
                      >
                        <div className="alphabat">
                          <span>B</span>
                        </div>
                        <div className="form_text_check">
                          <h5 style={{ width: "20rem" }}>
                            Create new website
                            <span className="no-active">
                              <i class="fa-solid fa-check"></i>
                            </span>
                          </h5>
                        </div>
                      </div>
                      <span className="two_butn_error"></span>
                      <div
                        className="ok_butn"
                        onClick={twoTrue ? okHandler : butnTwoError}
                      >
                        <span>Next</span>
                      </div>
                    </div>
                    {/* form butn end here */}
                  </div>
                </div>
                {/* form_image_three */}
                <div className="form_image_three form_image_one">
                  <div className="bg" style={{ height: "24rem" }}>
                    {/* form text start here */}
                    <div className="form_text">
                      <span className="number_span">3</span>
                      <span>
                        <h2>
                          Estimated number of pages required on the website
                          {/* <span>required on the website?*</span> */}
                        </h2>
                      </span>
                    </div>
                    {/* form text end here */}
                    {/* form butn start here */}
                    <div className="form_Input">
                      <input
                        type="text"
                        placeholder="Type your answer here"
                        name="number_page"
                        onChange={changeHandler}
                        onBlur={blurHandler}
                        pattern={pageNum_pattern}
                        value={inputValues.number_page}
                        required
                      />

                      <span id="numberPage"></span>
                      <div className="ok_btn_press_enter">
                        <div
                          className="ok_butn"
                          style={{ marginTop: "0" }}
                          onClick={
                            inputValues.number_page === "" ||
                            !inputValues.number_page.match(pageNum_pattern)
                              ? errorHandler
                              : okHandler
                          }
                        >
                          <span>Next</span>
                        </div>
                        <div className="press_enter">
                          <span>Press Enter ↵</span>
                        </div>
                      </div>
                    </div>
                    {/* form butn end here */}
                  </div>
                </div>
                {/* form_image_four */}
                <div className="form_image_four form_image_one">
                  <div className="bg" style={{ height: "40rem" }}>
                    {/* form text start here */}
                    <div className="form_text">
                      <span className="number_span">4</span>
                      <span>
                        <h2>
                          Please enter your website domain?*
                          {/* <span>looking for?*</span> */}
                        </h2>
                      </span>
                    </div>
                    {/* form text end here */}
                    {/* form butn start here */}
                    <div className="form_Input">
                      <input
                        type="text"
                        placeholder="https://"
                        name="domain_name"
                        onChange={changeHandler}
                        onBlur={blurHandler}
                        pattern={domainName}
                        value={inputValues.domain_name}
                        required
                      />

                      <span id="domainName"></span>
                      <div className="ok_btn_press_enter">
                        <div
                          className="ok_butn"
                          style={{ marginTop: "0" }}
                          onClick={
                            inputValues.domain_name === "" ||
                            !inputValues.domain_name.match(domainName)
                              ? errorHandler
                              : okHandler
                          }
                        >
                          <span>Next</span>
                        </div>
                        <div className="press_enter">
                          <span>Press Enter ↵</span>
                        </div>
                      </div>
                    </div>
                    {/* form butn end here */}
                  </div>
                </div>
                {/* four_image_four */}
                {/* form_image_five */}
                <div className="form_image_five form_image_one">
                  <div className="bg" style={{ height: "40rem" }}>
                    {/* form text start here */}
                    <div className="form_text">
                      <span className="number_span">5</span>
                      <span>
                        <h2>
                          What is the size of your company?*
                          {/* <span>looking for?*</span> */}
                        </h2>
                      </span>
                    </div>
                    {/* form text end here */}
                    {/* form butn start here */}
                    <div className="form_butn">
                      {/* form butn start here */}
                      <div
                        className="form_butn_one button butn_five"
                        onClick={(e) => toggleActive(e, "five", 4)}
                        style={{ width: "25rem" }}
                        onMouseDown={() => setFiveTrue(true)}
                      >
                        <div className="alphabat">
                          <span>A</span>
                        </div>
                        <div className="form_text_check">
                          <h5 style={{ width: "20rem" }}>
                            Less than 50
                            <span className="no-active">
                              <i class="fa-solid fa-check"></i>
                            </span>
                          </h5>
                        </div>
                      </div>
                      {/* button A end */}
                      {/* form butn one end */}
                      <div
                        className="form_butn_two button butn_five"
                        onClick={(e) => toggleActive(e, "six", 5)}
                        style={{ width: "25rem" }}
                        onMouseDown={() => setFiveTrue(true)}
                      >
                        <div className="alphabat">
                          <span>B</span>
                        </div>
                        <div className="form_text_check">
                          <h5 style={{ width: "20rem" }}>
                            Less than 50
                            <span className="no-active">
                              <i class="fa-solid fa-check"></i>
                            </span>
                          </h5>
                        </div>
                      </div>
                      {/* button B end */}
                      {/* button C start */}
                      <div
                        className="form_butn_two button butn_five"
                        onClick={(e) => toggleActive(e, "seven", 6)}
                        style={{ width: "25rem" }}
                        onMouseDown={() => setFiveTrue(true)}
                      >
                        <div className="alphabat">
                          <span>C</span>
                        </div>
                        <div className="form_text_check">
                          <h5 style={{ width: "20rem" }}>
                            201-500
                            <span className="no-active">
                              <i class="fa-solid fa-check"></i>
                            </span>
                          </h5>
                        </div>
                      </div>
                      {/* button C end */}
                      {/* button D start */}
                      <div
                        className="form_butn_two button butn_five"
                        onClick={(e) => toggleActive(e, "eight", 7)}
                        style={{ width: "25rem" }}
                        onMouseDown={() => setFiveTrue(true)}
                      >
                        <div className="alphabat">
                          <span>D</span>
                        </div>
                        <div className="form_text_check">
                          <h5 style={{ width: "20rem" }}>
                            More than 50
                            <span className="no-active">
                              <i class="fa-solid fa-check"></i>
                            </span>
                          </h5>
                        </div>
                      </div>
                      <span className="five_butn_error"></span>
                      {/* button D end */}
                      <div
                        className="ok_butn"
                        onClick={fiveTrue ? okHandler : butnFiveError}
                      >
                        <span>Next</span>
                      </div>
                    </div>
                    {/* form butn end here */}
                  </div>
                </div>
                {/* form_image_five */}
                {/* form_image_six */}
                <div className="form_image_six form_image_one">
                  <div className="bg" style={{ height: "40rem" }}>
                    {/* form text start here */}
                    <div className="form_text">
                      <span className="number_span">6</span>
                      <span>
                        <h2>
                          What's your company name?*
                          {/* <span>looking for?*</span> */}
                        </h2>
                      </span>
                    </div>
                    {/* form text end here */}
                    {/* form butn start here */}
                    <div className="form_Input">
                      <input
                        type="text"
                        placeholder="Type your answer here"
                        name="company_name"
                        onChange={changeHandler}
                        onBlur={blurHandler}
                        pattern={companyNamePattern}
                        value={inputValues.company_name}
                        required
                      />

                      <span id="companyName"></span>
                      <div className="ok_btn_press_enter">
                        <div
                          className="ok_butn"
                          style={{ marginTop: "0" }}
                          onClick={
                            inputValues.company_name === "" ||
                            !inputValues.company_name.match(companyNamePattern)
                              ? errorHandler
                              : okHandler
                          }
                        >
                          <span>Next</span>
                        </div>
                        <div className="press_enter">
                          <span>Press Enter ↵</span>
                        </div>
                      </div>
                    </div>
                    {/* form butn end here */}
                  </div>
                </div>
                {/* form_image_six */}
                {/* form_image_seven */}
                <div className="form_image_seven form_image_one">
                  <div className="bg" style={{ height: "40rem" }}>
                    {/* form text start here */}
                    <div className="form_text">
                      <span className="number_span">7</span>
                      <span>
                        <h2>
                          What is your first name?*
                          {/* <span>looking for?*</span> */}
                        </h2>
                      </span>
                    </div>
                    {/* form text end here */}
                    {/* form butn start here */}
                    <div className="form_Input">
                      <input
                        type="text"
                        placeholder="Type your answer here"
                        name="first_name"
                        onChange={changeHandler}
                        onBlur={blurHandler}
                        pattern={firstNamePattern}
                        value={inputValues.first_name}
                        required
                      />

                      <span id="firstName"></span>
                      <div className="ok_btn_press_enter">
                        <div
                          className="ok_butn"
                          style={{ marginTop: "0" }}
                          onClick={
                            inputValues.first_name === "" ||
                            !inputValues.first_name.match(firstNamePattern)
                              ? errorHandler
                              : okHandler
                          }
                        >
                          <span>Next</span>
                        </div>
                        <div className="press_enter">
                          <span>Press Enter ↵</span>
                        </div>
                      </div>
                    </div>
                    {/* form butn end here */}
                  </div>
                </div>
                {/* form_image_seven */}
                {/* form_image_eight */}
                <div className="form_image_eight form_image_one">
                  <div className="bg" style={{ height: "40rem" }}>
                    {/* form text start here */}
                    <div className="form_text">
                      <span className="number_span">8</span>
                      <span>
                        <h2>
                          What is your last name?*
                          {/* <span>looking for?*</span> */}
                        </h2>
                      </span>
                    </div>
                    {/* form text end here */}
                    {/* form butn start here */}
                    <div className="form_Input">
                      <input
                        type="text"
                        placeholder="Type your answer here"
                        name="last_name"
                        onChange={changeHandler}
                        onBlur={blurHandler}
                        pattern={lastNamePattern}
                        value={inputValues.last_name}
                        required
                      />

                      <span id="lastName"></span>
                      <div className="ok_btn_press_enter">
                        <div
                          className="ok_butn"
                          style={{ marginTop: "0" }}
                          onClick={
                            inputValues.last_name === "" ||
                            !inputValues.last_name.match(lastNamePattern)
                              ? errorHandler
                              : okHandler
                          }
                        >
                          <span>Next</span>
                        </div>
                        <div className="press_enter">
                          <span>Press Enter ↵</span>
                        </div>
                      </div>
                    </div>
                    {/* form butn end here */}
                  </div>
                </div>
                {/* form_image_eight */}
                {/* form_image_nine */}
                <div className="form_image_nine form_image_one">
                  <div className="bg center" style={{ height: "24rem" }}>
                    {/* form text start here */}
                    <div className="form_text">
                      <span className="number_span">9</span>
                      <span>
                        <h2 className="form_nine">
                          Hi, What’s your role in the company
                          <span>
                            (Eg. Marketing Manager, Project Manager etc.)
                          </span>
                        </h2>
                      </span>
                    </div>
                    {/* form text end here */}
                    {/* form butn start here */}
                    <div className="form_Input">
                      <input
                        type="text"
                        placeholder="Type your answer here"
                        name="company_role"
                        onChange={changeHandler}
                        onBlur={blurHandler}
                        pattern={companyRolePattern}
                        value={inputValues.company_role}
                        required
                      />

                      <span id="companyRole"></span>
                      <div className="ok_btn_press_enter">
                        <div
                          className="ok_butn"
                          style={{ marginTop: "0" }}
                          onClick={
                            inputValues.company_role === "" ||
                            !inputValues.company_role.match(companyRolePattern)
                              ? errorHandler
                              : okHandler
                          }
                        >
                          <span>Next</span>
                        </div>
                        <div className="press_enter">
                          <span>Press Enter ↵</span>
                        </div>
                      </div>
                    </div>
                    {/* form butn end here */}
                  </div>
                </div>
                {/* form_image_nine */}
                {/* form_image_ten */}
                <div className="form_image_ten form_image_one">
                  <div className="bg center" style={{ height: "24rem" }}>
                    {/* form text start here */}
                    <div className="form_text">
                      <span className="number_span">10</span>
                      <span>
                        <h2 className="form_ten">
                          In which city you are located
                          <span>Example: Mumbai, Bangalore, Delhi etc</span>
                        </h2>
                      </span>
                    </div>
                    {/* form text end here */}
                    {/* form butn start here */}
                    <div className="form_Input">
                      <input
                        type="text"
                        placeholder="Type your answer here"
                        name="city"
                        onChange={changeHandler}
                        onBlur={blurHandler}
                        pattern={cityPattern}
                        value={inputValues.city}
                        required
                      />

                      <span id="city"></span>
                      <div className="ok_btn_press_enter">
                        <div
                          className="ok_butn"
                          style={{ marginTop: "0" }}
                          onClick={
                            inputValues.city === "" ||
                            !inputValues.city.match(cityPattern)
                              ? errorHandler
                              : okHandler
                          }
                        >
                          <span>Next</span>
                        </div>
                        <div className="press_enter">
                          <span>Press Enter ↵</span>
                        </div>
                      </div>
                    </div>
                    {/* form butn end here */}
                  </div>
                </div>
                {/* form_image_ten */}
                {/* form_image_eleven */}
                <div className="form_image_eleven form_image_one">
                  <div className="bg center" style={{ height: "24rem" }}>
                    {/* form text start here */}
                    <div className="form_text">
                      <span className="number_span">11</span>
                      <span>
                        <h2 className="form_eleven">
                          What's your email id
                          {/* <span>Example: Mumbai, Bangalore, Delhi etc</span>  */}
                        </h2>
                      </span>
                    </div>
                    {/* form text end here */}
                    {/* form butn start here */}
                    <div className="form_Input">
                      <input
                        type="email"
                        placeholder="name@example.com"
                        name="email"
                        onChange={changeHandler}
                        onBlur={blurHandler}
                        pattern={emailPattern}
                        value={inputValues.email}
                        required
                      />

                      <span id="email"></span>
                      <div className="ok_btn_press_enter">
                        <div
                          className="ok_butn"
                          style={{ marginTop: "0" }}
                          onClick={
                            inputValues.email === "" ||
                            !inputValues.email.match(emailPattern)
                              ? errorHandler
                              : okHandler
                          }
                        >
                          <span>Next</span>
                        </div>
                        <div className="press_enter">
                          <span>Press Enter ↵</span>
                        </div>
                      </div>
                    </div>
                    {/* form butn end here */}
                  </div>
                </div>
                {/* form_image_eleven */}

                {/* form_image_thridteen */}
                <div
                  className="form_image_thriteen form_image_one"
                  style={{ paddingLeft: "0" }}
                >
                  <div className="bg center" style={{ height: "24rem" }}>
                    {/* form text start here */}
                    <div
                      className="form_text"
                      style={{ display: "grid", placeItems: "center" }}
                    >
                      <div className="thankYou_Image">
                      <img src={thankYou} alt="thankYou" width="50" />
                      </div>
                      <span>
                        <h2 className="thanyou_heading">
                          Thank you for your interest. Our Technical person will
                          contact you shortly.
                        </h2>
                      </span>
                    </div>
                  </div>
                </div>
                {/* form_image_thridteen */}
              </div>
            </div>

            {/* image section start here */}
            <div className="image_bg">
              <div className="Image">
                <div className="imgs image_one ">
                  <div className="logo_image">
                    <img src={dwsitLogo} alt="dwsitLogo" />
                  </div>
                  <div className="image_heading">
                    <h2>
                      Most trusted website brand
                      <span>in India</span>
                    </h2>
                    <p>Join 3000+ happy customers globally</p>
                  </div>
                </div>
                <div className=" imgs image_two">
                  <div className="logo_image">
                    <img src={formTwo} alt="dwsitLogo" />
                  </div>
                  <div className="image_heading">
                    <h2>what is your goal?</h2>
                    <p> Redesign existing website</p>
                    <p> Create new website</p>
                  </div>
                </div>
                <div className=" imgs image_three">
                  <div className="logo_image">
                    <img src={formTwo} alt="dwsitLogo" />
                  </div>
                  <div className="image_heading">
                    <h2>
                      Our pricing is based on no. of
                      <span>pages</span>
                    </h2>
                  </div>
                </div>
                <div className=" imgs image_four">
                  <div className="logo_image">
                    <img src={formTwo} alt="dwsitLogo" />
                  </div>
                  <div className="image_heading">
                    <h2>Your website will help us</h2>
                    <p>to create better proposal</p>
                  </div>
                </div>
                <div className="imgs image_five">
                  <div className="logo_image">
                    <img src={formTwo} alt="dwsitLogo" />
                  </div>
                  <div className="image_heading">
                    <h2>
                      We have worked with all range
                      <span>of companies</span>
                    </h2>
                  </div>
                  <div className="company_logo_image">
                    <img src={formFive} alt="dwsitLogo" />
                  </div>
                </div>
                <div className="imgs image_six">
                  <div className="logo_image">
                    <img src={dwsitLogo} alt="dwsitLogo" />
                  </div>
                  <div className="image_heading">
                    <h2>
                      Become a Part of dwsit
                      <span>company</span>
                    </h2>
                  </div>
                </div>
                <div className="imgs image_seven">
                  <div className="logo_image">
                    <img src={formTwo} alt="dwsitLogo" />
                  </div>
                  <div className="image_heading">
                    <h2>
                      what is should we call you?
                      <span>Firstname</span>
                    </h2>
                  </div>
                </div>
                <div className="imgs image_eight">
                  <div className="logo_image">
                    <img src={formTwo} alt="dwsitLogo" />
                  </div>
                  <div className="image_heading">
                    <h2>
                      what is should we call you?
                      <span>LastName</span>
                    </h2>
                  </div>
                </div>
                <div className="imgs image_nine" id="image_nine">
                <div className="logo_image">
                    <img src={formTwo} alt="dwsitLogo" />
                  </div>
                  <div className="image_heading">
                    <h2>
                      your post in your company
                    </h2>
                  </div>
                </div>
                <div className="imgs image_ten">
                <div className="logo_image">
                    <img src={formTwo} alt="dwsitLogo" />
                  </div>
                  <div className="image_heading">
                    <h2>
                     where is your company located
                    </h2>
                  </div>
                </div>
                <div className="imgs image_eleven" id="image_eleven">
                <div className="logo_image">
                    <img src={formTwo} alt="dwsitLogo" />
                  </div>
                  <div className="image_heading">
                    <h2>
                     we'll send you the website quotation
                    <span>on this email id</span>
                    </h2>
                  </div>
                </div>
                <div className="imgs image_twelve" id="image_twelve">
                  <img src="" alt="" />
                </div>
                <div className="xmark_div" onClick={hideFormHandler}>
                  <i class="fa-solid fa-xmark"></i>
                </div>
              </div>
            </div>

            {/* main form image end here */}
          </div>
          {/* main form image end here */}
        </div>
      </div>
    </>
  );
};

export default Form;
