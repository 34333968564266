import React, { useEffect } from "react";
import image from "../../assets/image/digitalmarketing/drive/image.png";
// import fingerOne from "../../assets/image/digitalmarketing/drive/fingerOne.png";
// import fingerTwo from "../../assets/image/digitalmarketing/drive/fingerTwo.png";
// import fingerThree from "../../assets/image/digitalmarketing/drive/fingerThree.png";
// import fingerFive from "../../assets/image/digitalmarketing/drive/fingerFive.png";
// import fingerSix from "../../assets/image/digitalmarketing/drive/fingerSix.png";
// import fingerSeven from "../../assets/image/digitalmarketing/drive/fingerSeven.png";
import DriveStyle from "../../assets/style/digitalmarketing/drive.js";
const Drive = () => {

 
  return (
    <>
      <DriveStyle />
      <div className="container-fluid">
        <div className="">
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
            <div className="main_drive">
              <div className="second_drive">
                <div className="drive_heading_image">
                  <div className="content-wrapper">
                    <div className="drive_heading">
                      <h2>
                        How do we drive
                        <span>traffic, qualified</span>
                        <span>leads, and</span>
                        <span>conversions?</span>
                      </h2>
                    </div>
                    <div className="drive_image">
                      <img src={image} alt="image" />
                    </div>
                  </div>
                </div>
                {/* finger start here */}
                <div className="drive_fingers">
                  <div className="drive_finger_one">
                    <div className="finger_image">
                    <i class="fa-regular fa-square-check"></i>
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>Create a custom website for your brand</h4>
                      <div className="drive_para">
                        <p>
                          A professionally crafted website lays the foundation
                          for brand building by establishing a robust online
                          presence and fostering trust. Custom websites instill
                          confidence by showcasing your unique personality,
                          credibility, and expertise. Highlight what sets you
                          and your business apart! Whether you’re selling
                          products or offering services, a custom website design
                          is essential. Building the ideal website can be
                          daunting, but it doesn’t have to be. Seven Fusions’
                          team assists you in creating a stunning website that
                          authentically reflects your brand’s narrative and
                          values.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="drive_finger_two">
                    <div className="finger_image">
                    <i class="fa-regular fa-square-check"></i>
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>Enhance Performance & User Experience</h4>
                      <div className="drive_para">
                        <p>
                          To effectively engage your audience and prompt action,
                          we prioritize seamless website navigation and
                          responsiveness across all devices. Improving website
                          loading speed significantly enhances user experience
                          and boosts search engine rankings.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="drive_finger_three">
                    <div className="finger_image">
                    <i class="fa-regular fa-square-check"></i>
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>High-Quality Content Copy</h4>
                      <div className="drive_para">
                        <p>
                          We implement the E-A-T (Expertise, Authoritativeness,
                          Trustworthiness) approach to produce high-quality
                          copy. With years of experience in crafting compelling
                          website content tailored to search intent, we ensure
                          your message resonates with your audience effectively.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="drive_finger_four">
                    <div className="finger_image">
                    <i class="fa-regular fa-square-check"></i>
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>Optimize For Search Engines</h4>
                      <div className="drive_para">
                        <p>
                          Given Google’s dominance in internet searches,
                          optimizing your website for search engines is
                          non-negotiable. Our team at Seven Fusions employs
                          proven SEO strategies to enhance your website’s
                          visibility and drive organic traffic.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="drive_finger_five">
                    <div className="finger_image">
                    <i class="fa-regular fa-square-check"></i>
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>Drive Quality & Niche Traffic</h4>
                      <div className="drive_para">
                        <p>
                          Generating traffic to your site can be challenging,
                          but with Seven Fusions’ effective organic and paid
                          strategies, we help drive quality traffic and generate
                          leads with high conversion rates.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="drive_finger_six">
                    <div className="finger_image">
                    <i class="fa-regular fa-square-check"></i>
                    </div>
                    <div className="border-right"></div>
                    <div className="drive_finger_para">
                      <h4>Track Conversion, Refine & Improve </h4>
                      <div className="drive_para">
                        <p>
                          After analyzing analytics data, we refine and improve
                          marketing efforts by amplifying successful strategies
                          and discontinuing ineffective ones. With continuous
                          tracking and refinement, we ensure your website
                          achieves optimal performance and meets your business
                          objectives.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* finger end here */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Drive;
