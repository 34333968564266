import React from "react";
import american from "../../assets/image/digitalmarketing/footer/american.jpg";
import discover from "../../assets/image/digitalmarketing/footer/discover.jpg";
import masterCard from "../../assets/image/digitalmarketing/footer/masterCard.jpg";
import paypal from "../../assets/image/digitalmarketing/footer/paypal.jpg";
import visa from "../../assets/image/digitalmarketing/footer/visa.jpg";
import FooterStyle from "../../assets/style/digitalmarketing/footer.js";
const Footer = () => {
  return (
    <>
    <FooterStyle/>
      <div className="main_footer">
        <div className="second_footer">
          <div className="footer">
            <div className="footer_dwsit_with_year">
              <h1>© 2024 Dwsit</h1>
            </div>
            <div className="footer_payment_logo">
              <div className="footer_logo_one">
                <img src={american} alt="american" />
              </div>
              <div className="footer_logo_two">
                <img src={discover} alt="discover" />
              </div>
              <div className="footer_logo_three">
                <img src={masterCard} alt="masterCard" />
              </div>
              <div className="footer_logo_four">
                <img src={paypal} alt="paypal" />
              </div>
              <div className="footer_logo_five">
                <img src={visa} alt="visa" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
