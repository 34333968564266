import React, { useState } from "react";
import QuestionStyle from "../../assets/style/ecomerce/question.js";

const Question = () => {
  const showHandler = (event) => {
    const Answer = document.querySelectorAll(".answer");
    const downArrow = document.querySelectorAll(".fa-chevron-down");
    const currentQuestion = event.currentTarget;

    const currentDownArrow = currentQuestion.querySelector(".fa-chevron-down");
    const currentAnswer = currentQuestion.querySelector(".answer");
    const isActive = currentAnswer.classList.contains("active");

    Answer.forEach((item) => {
      item.classList.remove("active");
    });
    downArrow.forEach((item) => {
      item.classList.remove("arrowActive");
    });

    if (!isActive) {
      currentAnswer.classList.add("active");
      currentDownArrow.classList.add("arrowActive");
    }
  };
  return (
    <>
      <QuestionStyle />
      <div className="main_question_section">
        <div className="question_section">
          {/* question one */}
          <div className="question_grid_section">
            <div className="question question_one" onClick={showHandler}>
              <div className="question_heading">
                <div className="heading">
                  <div className="q_one">
                    <span>1</span>
                  </div>
                  <h3>Is WordPress free to use or do I need to pay for it?</h3>
                </div>
                <div className="down_arrow">
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="answer answer_one">
                <p className="text-muted mb-0 faq-ans">
                  Yes, WordPress is free to use. It is an open-source content
                  management system (CMS). However, you may need to pay for web
                  hosting, a domain name, and certain features or plugins,
                  depending on your needs. toggleitionally, there are some
                  premium themes and plugins that come at a cost.
                </p>
              </div>
            </div>
            {/* question two */}
            <div className="question question_two" onClick={showHandler}>
              <div className="question_heading">
                <div className="heading">
                  <div className="q_one">
                    <span>2</span>
                  </div>
                  <h3> How secure is WordPress?</h3>
                </div>
                <div className="down_arrow">
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="answer answer_one">
                <p class="text-muted mb-0 faq-ans">
                  WordPress has a dedicated security team that works to identify
                  and fix security issues as quickly as possible, and they
                  release regular updates and security patches to address any
                  known vulnerabilities. We use several security plugins that
                  helps to secure your WordPress website and protect it even
                  futher from threats.
                </p>
              </div>
            </div>
            {/* question three */}
            <div className="question question_three" onClick={showHandler}>
              <div className="question_heading">
                <div className="heading">
                  <div className="q_one">
                    <span>3</span>
                  </div>
                  <h3> How secure is WordPress?</h3>
                </div>
                <div className="down_arrow">
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="answer answer_one">
                <p class="text-muted mb-0 faq-ans">
                  Yes, you can make changes to a WordPress website even after it
                  goes live. One of the benefits of using WordPress is that it
                  allows you to easily update and modify your website content,
                  design, and functionality.
                </p>
              </div>
            </div>
            {/* question four */}
            <div className="question question_four" onClick={showHandler}>
              <div className="question_heading">
                <div className="heading">
                  <div className="q_one">
                    <span>4</span>
                  </div>
                  <h3> What factors affect the cost of a website?</h3>
                </div>
                <div className="down_arrow">
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="answer answer_one">
                <p class="text-muted mb-0 faq-ans">
                  The cost of a WordPress website can vary depending on several
                  factors. 1. Website Hosting 2. No. of pages required 3. No. of
                  products (E-Commerce) 4. Paid plugins 5. Content Writing 5.
                  Maintenance &amp; support
                </p>
              </div>
            </div>
            {/* question five */}
            <div className="question question_five" onClick={showHandler}>
              <div className="question_heading">
                <div className="heading">
                  <div className="q_one">
                    <span>5</span>
                  </div>
                  <h3> Do I need to provide entire content for my website?</h3>
                </div>
                <div className="down_arrow">
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="answer answer_one">
                <p class="text-muted mb-0 faq-ans">
                  No, but atleast you will have to provide key points for each
                  page of your website to make sure content is very specific to
                  your business.futher our team of content writers will use that
                  key points to write content for your website
                </p>
              </div>
            </div>
          </div>
          <div className="question_grid_section_two">
            {/* question six */}
            <div className="question question_six" onClick={showHandler}>
              <div className="question_heading">
                <div className="heading">
                  <div className="q_one">
                    <span>6</span>
                  </div>
                  <h3>How do you handle design approvals?</h3>
                </div>
                <div className="down_arrow">
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="answer answer_one">
                <p class="text-muted mb-0 faq-ans">
                  Design approvals are an important part of our design process,
                  for that we typically use 3 revisison rounds to ensure that
                  the final website design meets the client's expectations and
                  requirements.
                </p>
              </div>
            </div>
            {/* question seven */}
            <div className="question question_seven" onClick={showHandler}>
              <div className="question_heading">
                <div className="heading">
                  <div className="q_one">
                    <span>7</span>
                  </div>
                  <h3> How much time it takes to complete a website?</h3>
                </div>
                <div className="down_arrow">
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="answer answer_one">
                <p class="text-muted mb-0 faq-ans">
                  It usually takes around 6-8 weeks to complete a project
                  depending on complexity and timley availability of client.
                </p>
              </div>
            </div>
            {/* question eight */}
            <div className="question question_eight" onClick={showHandler}>
              <div className="question_heading">
                <div className="heading">
                  <div className="q_one">
                    <span>8</span>
                  </div>
                  <h3> What is LMS?</h3>
                </div>
                <div className="down_arrow">
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="answer answer_one">
                <p class="text-muted mb-0 faq-ans">
                  LMS stands for Learning Management System. An LMS is a
                  software platform used to manage, deliver, and track online
                  educational courses and training programs.
                </p>
              </div>
            </div>
            {/* question nine */}
            <div className="question question_nine" onClick={showHandler}>
              <div className="question_heading">
                <div className="heading">
                  <div className="q_one">
                    <span>9</span>
                  </div>
                  <h3> What are your payment terms for any new project?</h3>
                </div>
                <div className="down_arrow">
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="answer answer_one">
                <p class="text-muted mb-0 faq-ans">
                  Our Payment terms: 1. 40% amount advance to start the project.
                  2. 30% amount after approval of designs. 3. 20% amount after
                  completion of development. 4. 10% amount after launch
                </p>
              </div>
            </div>
            {/* question ten */}
            <div className="question question_ten" onClick={showHandler}>
              <div className="question_heading">
                <div className="heading">
                  <div className="q_one">
                    <span>10</span>
                  </div>
                  <h3> What if I don't like the design, I'm I protected?</h3>
                </div>
                <div className="down_arrow">
                  <i className="fa-solid fa-chevron-down"></i>
                </div>
              </div>
              <div className="answer answer_one">
                <p class="text-muted mb-0 faq-ans">
                  We are committed to providing our customers with the highest
                  quality website design services. If for any reason you are not
                  completely satisfied with our work, we offer a satisfaction
                  guarantee: We will work with you until you are completely
                  satisfied with the design of your website. We will provide up
                  to three rounds of revisions free of charge to ensure that
                  your website meets your expectations. Our goal is to exceed
                  your expectations and create a website that reflects your
                  unique brand and vision. If you have any questions about our
                  satisfaction guarantee, please don't hesitate to contact us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Question;
