import React from 'react';
import mapOffice from "../../assets/image/digitalmarketing/office/map-office.png";
import websiteReady from "../../assets/image/digitalmarketing/office/website-ready.png";
import OfficeStyle from '../../assets/style/digitalmarketing/office.js';
const Office = () => {
  return (
    <>
    <OfficeStyle/>
     <div className="">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

            {/* main_office_div start here */}
            <div className="main_office_div">
                <div className="second_office_div">

                    {/* office start here */}

                    <div className="office">
                        {/* heading start here */}
                        <div className="office_heading">
                            <h2>
                                We are present in 7 cities across the India. 
                                <span>3000+ Happy Customers Globally</span>
                            </h2>
                        </div>
                        {/* heading end here */}

                        {/* office image start here */}
                         <div className="office_image">
                            <div className="office_one">
                                <img src={websiteReady} alt="websiteReady" />
                            </div>
                            <div className="border_image"></div>
                            <div className="office_two">
                                <img src={mapOffice} alt="mapOffice" />
                            </div>
                         </div>
                         {/* office image end here */}

                    </div>
                 
                     {/* office end here */}

                </div>
            </div>
            {/* main_office_div end here */}

        </div>
     </div>
    </>
  )
}

export default Office;