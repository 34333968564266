import { createGlobalStyle } from "styled-components";

const DriveStyle = createGlobalStyle`
*,*::after,*::before{
    padding:0;
    margin:0;
    box-sizing:border-box;

}
:root{
    --font-family:  "Montserrat", sans-serif;
    --weight-eight:800;
    --weight-six:600;
    --weight-five:500;
    --weight-four:400;
    --weight-three:300;
    --para-cl:#b6b6b666;
    --border-cl:#3D2E70;
    --color:#fff;
    --box-font-color:rbga(0,0,0);
}
html,body{
    font-size:62.5%;
    font-family:var(--font-family);
}
.main_drive{
  background: #008275;
  padding-top: 10rem;
  padding-bottom:10rem;
}
.second_drive{
  display: grid;
  grid-template-columns: repeat(2,max-content);
  place-content: center;
  padding-left:5%;
  padding-right:5%;
  column-gap: 6rem;
 
}
.drive_heading_image{
    /* heading start here */
    .content-wrapper{
     position:sticky;
     top:40px;
    .drive_heading{
        h2{
            display: flex;
           flex-direction:column;
           text-transform: capitalize;
           font-weight:var(--weight-eight);
           font-size:4rem;
           color:var(--color);
        }

    }
    /* heading end here */
    .drive_image{
        max-width: 36rem;
        min-width: 10rem;
        margin-top: 2rem;
        img{
          width: 100%;
          height: 100%;
          border-radius:1rem;
        }
    }
    /*  */
}
}
/* drive fingers start here */
.drive_fingers{
     display:flex;
     flex-direction:column;
     row-gap: 2.5rem;
    .drive_finger_one,
    .drive_finger_two,
    .drive_finger_three,
    .drive_finger_four,
    .drive_finger_five,
    .drive_finger_six{
         display:grid;
         grid-template-columns: repeat(3,max-content);
         align-items: center;
         column-gap: 2rem;
         /* border:1px solid var(--border-cl); */
         border-radius:1rem;
         padding-top:3rem;
         padding-bottom:3rem;
         padding-right:1rem;
         background:#1be0cd;
         .finger_image{
            max-width:6rem;
            min-width:5rem;
            display: grid;
            place-content: center;
            .fa-regular{
                font-size: 3rem;
                color: #00000061;
           }
         }
         /* border-right start here */
         .border-right{
            /* border:.1px solid #3D2E70; */
            opacity: 0.3;
            height: 100%;
         }
         /* border-right end here */
         /* drive finger para start here */
         .drive_finger_para{
            max-width:45rem; 
            min-width:5rem;
           
            h4{
                font-size:2rem;
                font-weight:var(--weight-six);
                font-family:var(--font-family);
                color:var(--box-font-color);
            }
            .drive_para{
               
                p{
                    color:var(--box-font-color);
                    font-size: 1.4rem;
                    line-height: 2.2rem;
                    font-family: var(--font-family);
                    width:100%;
                    text-align: left;
                }
            }
         }
         /* drive finger end here */
    }
}
/* drive finger end here */
@media (max-width:1224px){
    .second_drive{
        
        grid-template-columns: repeat(1,max-content);
        place-items: center;
        place-content: center;
    }
    .drive_heading_image {
        .content-wrapper{
      .drive_heading{
            h2{
                font-size:3rem;
                text-align: center;
            }
        } 
        }
        
    }  
    .drive_fingers{
        margin-top: 5rem;
    }
}
@media (max-width:780px){
    .drive_fingers {
        .drive_finger_one,  
        .drive_finger_two,  
        .drive_finger_three,  
        .drive_finger_four,  
        .drive_finger_five,  
        .drive_finger_six{

            column-gap:0;
            .border-right{
             border-right:0;
            }
        }
    }
}
@media (max-width:640px){
.drive_fingers {
        .drive_finger_one,  
        .drive_finger_two,  
        .drive_finger_three,  
        .drive_finger_four,  
        .drive_finger_five,  
        .drive_finger_six{
            grid-template-columns: repeat(1,max-content);
            .drive_finger_para{
                h4{
                    text-align: center;
                }
                .drive_para {
                    p{
                    text-align: center;
                }
                }
                
            }
        }
    }
}
@media (max-width:480px){
    .drive_fingers {
        .drive_finger_one,  
        .drive_finger_two,  
        .drive_finger_three,  
        .drive_finger_four,  
        .drive_finger_five,  
        .drive_finger_six{
          .drive_finger_para{
            max-width:30rem; 
            min-width:5rem;
          }
        }
    }
    .drive_heading_image {
        .content-wrapper{
            .drive_heading {
            h2 {
         font-size: 2.5rem;
     }
        }
        }
   
    } 
}
@media (max-width:360px){
    .drive_heading_image{
        .content-wrapper{
            .drive_image{
            max-width: 40rem;
            min-width: 10rem;
        }
        }
       
    }
}
`;

export default DriveStyle;
