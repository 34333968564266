import styled, { createGlobalStyle } from "styled-components";
const QuestionStyle = createGlobalStyle`
*,*::after,*::before{
    padding:0;
    margin:0;
    box-sizing:border-box;
}
:root{
    --heading-bg:#1be0cd;
    --faq-para-cl:#5b626b;
    --q-one:#fff;
}
html,body{
     font-size:62.5%;
}
 .question_section{
     margin-top: 5rem;
     margin-bottom: 5rem;
     display: grid;
     place-content: center;
     grid-template-columns: repeat(2,max-content);
     column-gap: 1rem;
    .question_grid_section,
    .question_grid_section_two{
    }
    .question{
        margin-bottom:1rem;
        .question_heading{
            height: 5rem;
            display: flex;
            align-items: center;
            column-gap: 0.1rem;
            flex-flow: row;
            padding-left: 2%;
            padding-right: 2%;
            cursor: pointer;
            user-select: none;
            background:var(--heading-bg);
            justify-content: space-between;
            
            .heading{
                text-align: left;
                display: flex;
                align-items: center;
                column-gap: 0.3rem;
                .q_one{
                background:var(--q-one);
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    font-size:1rem;
                }
            }
                h3{
                    font-size: 1.5rem;
                    padding: 20px 10px;
                }
            }
            .down_arrow{
                i{
                    font-size:1rem;
                }
            }
    }
    .answer{
        width:30rem;
        display: none;
        margin: 0 auto;
        padding-top:0.5rem;
        padding-bottom:0.5rem;
        p{
            color:var(--faq-para-cl);
            font-size:1.5rem;
        }
    }
    .active{
        display:block;
    }
    .arrowActive{
        transform:rotateX(180deg);
        transition:all 0.5s ease;
    }
    
    }
    
 }
 @media (max-width:999px){
    .question_section {
        grid-template-columns: repeat(1,max-content);
        /* .question{
            .question_heading{
                .heading h3 {
               font-size: 0.9rem;
}
            }
        } */
    }  
 }
 @media (max-width:560px){
    .question_section {
        .question{
            .question_heading{
                .heading h3 {
               font-size: 1.3rem;
}
            }
        }
    }  
 }
 /* @media (max-width:480px){
    .question_section {
        .question{
            .question_heading{
                .heading h3 {
               font-size: 0.7rem;
               }
               .down_arrow{
                 font-size:0.7rem;
               }
            }
        }
    }  
 } */
`;

export default QuestionStyle;
