import styled, { createGlobalStyle } from "styled-components";
const CustomerStyle = createGlobalStyle`
*,*::before,*::after{
    padding: 0;
    margin:0;
    box-sizing:border-box;
}
:root{
    --font-family:"Montserrat", sans-serif;
    --span-color: #5b626b;
}
html,body{
    font-size:62.5%;
    font-family:var(--font-family);

}
.customer_image{
    display:grid;
    grid-template-columns: repeat(4,max-content);
    place-content: center;
    place-items: center;
    margin-top: 8rem;
    margin-bottom: 3rem;
    column-gap: 4rem;
    .customer_image_one,
    .customer_image_two,
    .customer_image_three,
    .customer_image_four{
         max-width:15rem;
         min-width:5rem; 
         img{
            width:100%;
         }
    }

}
.customer_heading{
    display:grid;
    place-content: center;
    place-items: center;
    h1{
        text-align: center;
        font-size:3rem;
        font-weight: 500;
    }
    p{
        font-size:2rem;
        font-weight: 400;
       color:var(--span-color);
       margin-top: 1rem;
    }
}
.customer_form_Images{
  display: grid;
  place-content: center;
  place-items: center;
  grid-template-columns: repeat(3,max-content);
  margin-top: 1rem;
  column-gap: 3rem;
  .customer_image_one,
  .customer_image_two{
    max-width:10rem; 
    min-width:5rem;
    img{
        width: 100%;
    }
  }

}
/* media query start here for 780px  */
@media (max-width:780px){
    .customer_image{
        column-gap: 2.8rem;
    .customer_image_one,
    .customer_image_two,
    .customer_image_three,
    .customer_image_four{
         max-width:12rem;
         min-width:5rem; 
         img{
            width:100%;
         }
    }

}
/* heading start here  */
.customer_heading{
    h1{
        font-size:2.5rem;
    }
    p{
        font-size:1.8rem;
    }
}
/* heading end here */
/* customer image start here */
.customer_form_Images{
   grid-template-columns: repeat(1,max-content);
  .customer_image_one,
  .customer_image_two{
     display: none;
  }

}
/* customer image end here */
}

/* media query end here for 780px */

/* media query for 640px start here */
@media (max-width:992px){
    .customer_button{
        .btn{
          display: block;
        }
    }
}
@media (max-width:640px){
    .customer_image{
        column-gap: 2.8rem;
        grid-template-columns: repeat(2,max-content);
    .customer_image_one,
    .customer_image_two,
    .customer_image_three,
    .customer_image_four{
         max-width:15rem;
         min-width:5rem; 
         img{
            width:100%;
         }
    }

}
}
/* media query for 640px end here */
`;
export default CustomerStyle;
