import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";
import SliderStyle from "../../assets/style/digitalmarketing/slider.js";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const Slider = () => {
  const swiperRefLocal = useRef(null);
  useEffect(() => {
    const paragraf = document.querySelectorAll(".paragraf");
    paragraf.forEach((items) => {
      if (items.textContent.length > 51) {
        items.textContent = `${items.textContent.slice(0, 80)}...`;
      } 
    });
  }, []);
  return (
    <div
      onMouseEnter={() => swiperRefLocal?.current?.swiper?.autoplay?.stop()}
      onMouseLeave={() => swiperRefLocal?.current?.swiper?.autoplay?.start()}
      
    >
      <SliderStyle />
      <Swiper
        ref={swiperRefLocal}
        spaceBetween={10}
        grabCursor={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        //   dynamicBullets: true,
        // }}
        navigation={true}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          360: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          540: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
        modules={[Navigation, 
          // Pagination,
           Autoplay
          ]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="main_google_slider">
            <div className="google_image_logo">
              {/* google image year start here */}

              <div className="google_image_year">
                {/* <div className="google_image">
                  <img src={sliderImageOne} alt="sliderImageOne" />
                </div> */}
                <div className="google_heading">
                  <h4>Sujit Giri</h4>
                  <p>8 months ago</p>
                </div>
              </div>

              {/* google logo start here */}
              <div className="google_logo">
                <svg viewBox="0 0 512 512" height="18" width="18">
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z"
                      fill="#4285f4"
                    ></path>
                    <path
                      d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z"
                      fill="#34a853"
                    ></path>
                    <path
                      d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z"
                      fill="#fbbc05"
                    ></path>
                    <path
                      d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z"
                      fill="#ea4335"
                    ></path>
                    <path d="M20 20h472v472H20V20z"></path>
                  </g>
                </svg>
              </div>
            </div>
            {/* google image logo end here */}
            <div className="google_star">
              <span class="wp-stars">
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
              </span>
            </div>
            <div className="google_para">
              <p className="paragraf">
                Flown Developers.They have good-supported team,They respect the
                time delivery.All my Jeevandaan Foundation (NGO)Team Thanks you
                for creating Our website.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main_google_slider">
            <div className="google_image_logo">
              {/* google image year start here */}
              <div className="google_image_year">
                {/* <div className="google_image">
                  <img src={sliderImageTwo} alt="sliderImageTwo" />
                </div> */}
                <div className="google_heading">
                  <h4>Arshad Jamil</h4>
                  <p> 8 months ago</p>
                </div>
              </div>
              {/* google logo start here */}
              <div className="google_logo">
                <svg viewBox="0 0 512 512" height="18" width="18">
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z"
                      fill="#4285f4"
                    ></path>
                    <path
                      d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z"
                      fill="#34a853"
                    ></path>
                    <path
                      d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z"
                      fill="#fbbc05"
                    ></path>
                    <path
                      d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z"
                      fill="#ea4335"
                    ></path>
                    <path d="M20 20h472v472H20V20z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div className="google_star">
              <span class="wp-stars">
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
              </span>
            </div>
            <div className="google_para">
              <p className="paragraf">
                Excellent devlopment of my sites pages in affordable cost.All
                team members are very helpful and knolegble. Thankyou So much.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main_google_slider">
            <div className="google_image_logo">
              {/* google image year start here */}
              <div className="google_image_year">
                {/* <div className="google_image">
                  <img src={sliderImageThree} alt="sliderImageThree" />
                </div> */}
                <div className="google_heading">
                  <h4>mittul Gupta</h4>
                  <p>8 months ago</p>
                </div>
              </div>
              {/* google logo start here */}
              <div className="google_logo">
                <svg viewBox="0 0 512 512" height="18" width="18">
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z"
                      fill="#4285f4"
                    ></path>
                    <path
                      d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z"
                      fill="#34a853"
                    ></path>
                    <path
                      d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z"
                      fill="#fbbc05"
                    ></path>
                    <path
                      d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z"
                      fill="#ea4335"
                    ></path>
                    <path d="M20 20h472v472H20V20z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div className="google_star">
              <span class="wp-stars">
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
              </span>
            </div>
            <div className="google_para">
              <p className="paragraf">
                Thank you to Flown developer extremely professional easy to
                reach reasonable prices and very flexible in my experience Im
                from Delhi and it was pleasure to do business with them Thank
                you to team of Mr Jhangir alam and Intezam ali 🤝
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main_google_slider">
            <div className="google_image_logo">
              {/* google image year start here */}
              <div className="google_image_year">
                {/* <div className="google_image">
                  <img src={sliderImageFour} alt="sliderImageFour" />
                </div> */}
                <div className="google_heading">
                  <h4>Mobin R</h4>
                  <p>8 months ago</p>
                </div>
              </div>
              {/* google logo start here */}
              <div className="google_logo">
                <svg viewBox="0 0 512 512" height="18" width="18">
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z"
                      fill="#4285f4"
                    ></path>
                    <path
                      d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z"
                      fill="#34a853"
                    ></path>
                    <path
                      d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z"
                      fill="#fbbc05"
                    ></path>
                    <path
                      d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z"
                      fill="#ea4335"
                    ></path>
                    <path d="M20 20h472v472H20V20z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div className="google_star">
              <span class="wp-stars">
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
              </span>
            </div>
            <div className="google_para">
              <p className="paragraf">
                Excellent Service,thank you to everyone at Flown Developer for
                all their help highly recommend.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main_google_slider">
            <div className="google_image_logo">
              {/* google image year start here */}
              <div className="google_image_year">
                {/* <div className="google_image">
                  <img src={sliderImageFive} alt="sliderImageFive" />
                </div> */}
                <div className="google_heading">
                  <h4>stanshipproject</h4>
                  <p>9 months ago</p>
                </div>
              </div>
              {/* google logo start here */}
              <div className="google_logo">
                <svg viewBox="0 0 512 512" height="18" width="18">
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z"
                      fill="#4285f4"
                    ></path>
                    <path
                      d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z"
                      fill="#34a853"
                    ></path>
                    <path
                      d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z"
                      fill="#fbbc05"
                    ></path>
                    <path
                      d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z"
                      fill="#ea4335"
                    ></path>
                    <path d="M20 20h472v472H20V20z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div className="google_star">
              <span class="wp-stars">
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
              </span>
            </div>
            <div className="google_para">
              <p className="paragraf">
                Would like to thank team Flown developers for completing my
                website as per commitment , the cost is very reasonable & low
                compare to other website develops and they also do customization
                as per customers need...
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main_google_slider">
            <div className="google_image_logo">
              {/* google image year start here */}
              <div className="google_image_year">
                {/* <div className="google_image">
                  <img src={sliderImageSix} alt="sliderImageSix" />
                </div> */}
                <div className="google_heading">
                  <h4>Urvi Shelar</h4>
                  <p>9 months ago</p>
                </div>
              </div>
              {/* google logo start here */}
              <div className="google_logo">
                <svg viewBox="0 0 512 512" height="18" width="18">
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z"
                      fill="#4285f4"
                    ></path>
                    <path
                      d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z"
                      fill="#34a853"
                    ></path>
                    <path
                      d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z"
                      fill="#fbbc05"
                    ></path>
                    <path
                      d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z"
                      fill="#ea4335"
                    ></path>
                    <path d="M20 20h472v472H20V20z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div className="google_star">
              <span class="wp-stars">
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
              </span>
            </div>
            <div className="google_para">
              <p className="paragraf">
                Amazing work done within deadline. Super responsive team and
                great customer support. Kudos to Muddshir for her skills and
                Jehangir Ji for his customer centric approach.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main_google_slider">
            <div className="google_image_logo">
              {/* google image year start here */}
              <div className="google_image_year">
                {/* <div className="google_image">
                  <img src={sliderImageSeven} alt="sliderImageSeven" />
                </div> */}
                <div className="google_heading">
                  <h4>Himanshu Garg</h4>
                  <p>11 months ago</p>
                </div>
              </div>
              {/* google logo start here */}
              <div className="google_logo">
                <svg viewBox="0 0 512 512" height="18" width="18">
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z"
                      fill="#4285f4"
                    ></path>
                    <path
                      d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z"
                      fill="#34a853"
                    ></path>
                    <path
                      d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z"
                      fill="#fbbc05"
                    ></path>
                    <path
                      d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z"
                      fill="#ea4335"
                    ></path>
                    <path d="M20 20h472v472H20V20z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div className="google_star">
              <span class="wp-stars">
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
                <span class="wp-star">
                  <svg width="17" height="17" viewBox="0 0 1792 1792">
                    <path
                      d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      fill="#fb8e28"
                    ></path>
                  </svg>
                </span>
              </span>
            </div>
            <div className="google_para">
              <p className="paragraf">
                The team is very professional and they completed the project
                within stipulated time.They understood all my requirements
                before starting and delivered everything they promised.Would
                recommend everyone.Thanks team !!!
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Slider;