import React from "react";
import appDevelopment from "../../assets/image/dwsitlandingpage/servicesCard/app-development.svg";
// import contentWriting from "../../assets/image/dwsitlandingpage/servicesCard/content-writing.svg";
// import customerService from "../../assets/image/dwsitlandingpage/servicesCard/customer-service.svg";
// import logoDesign from "../../assets/image/dwsitlandingpage/servicesCard/logo-design.svg";
import responsive from "../../assets/image/dwsitlandingpage/servicesCard/responsive.svg";
import template from "../../assets/image/dwsitlandingpage/servicesCard/template.svg";
import ServiceCardStyled from "../../assets/style/dwsitlandingpage/serviceCard";
const ServiceCard = () => {
  return (
    <>
    <ServiceCardStyled/>
      <div className="main_serviceCard">
        <div className="second_serviceCard">
          <div className="service_section">
            {/* service start here */}
            <div className="ourServices">
              <h2>Our Service</h2>
              <span>
                We are a One-Stop Solution for delivering the best web design
                and development services. We render customized and affordable
                web design facilities to suit your requirements. Choose the best
                plans for building a responsive web design according to your
                needs.
              </span>
            </div>
            {/* service end here */}

            {/* service card start here */}
            <div className="main_service_cards">
              <div className="servce_cards">

                <div className="serivce_card_one">
                  <div className="service_card_image">
                  <img src={template} alt="template" />
                  </div>
                  <div className="service_card_heading">
                    <h1>Responsive Web Design</h1>
                  </div>
                </div>

                {/* <div className="serivce_card_two">
                  <div className="service_card_image">
                  <img src={customerService} alt="customerService" />
                  
                  </div>
                  <div className="service_card_heading">
                    <h1>24*7 Free Support</h1>
                     <p className="service_card_para">(Phone/Chat/Email)</p>
                  </div>
                </div> */}

                <div className="serivce_card_three">
                  <div className="service_card_image">
                  <img src={appDevelopment} alt="appDevelopement" />
                  </div>
                  <div className="service_card_heading">
                  <h1>Web Development</h1>
                  </div>
                </div>

                <div className="serivce_card_four">
                  <div className="service_card_image">
                  <img src={responsive} alt="responsive" />
                  </div>
                  <div className="service_card_heading">
                    <h1>Ecommerce Solution Development</h1>
                  </div>
                </div>

                {/* <div className="serivce_card_five">
                  <div className="service_card_image">
                  <img src={contentWriting} alt="contentWriting" />
                  </div>
                  <div className="service_card_heading">
                    <h1>Content Writing</h1>
                  </div>
                </div> */}

                {/* <div className="serivce_card_six">
                  <div className="service_card_image">
                  <img src={logoDesign} alt="logoDesign" />
                  </div>
                  <div className="service_card_heading">
                    <h1>Logo Designs</h1>
                  </div>
                </div> */}

              </div>
            </div>
          </div>
          {/* service card end here */}
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
