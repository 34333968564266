import styled, { createGlobalStyle } from "styled-components";
const IndustriesStyle = createGlobalStyle`
*,*::after,*::before{
    padding:0;
    margin:0;
    box-sizing:border-box;
}
:root{
    --font-family:"Montserrat", sans-serif;
    --weight-six:600;
    --weight-five:500;
    --icon-color: #26d170;
    --icon-bg:#fff;
    --icon-box-shadow: 1px -1px 20px 0 hsla(0,0%,70%,.26);
    --bg-cl:#f8fcfc;
    --hover-lihear:#008275;
}
html,body{
    font-size:62.5%;
    font-family:var(--font-family);
}
.industries{
    background: url(${(props) => props.Industries}),var(--bg-cl);
    height: 100%;
    width: 100%;
    background-repeat:repeat;
    /* height: max-content; */
    /* background-size:cover; */
    background-attachment: scroll;
    margin-top: 8rem;
    padding-top:5rem;
    .industries_heading_para{
        display: grid;
        place-items: center;
    /* industries heading start here */
    .industries_heading{
      h2{
        font-size:4.5rem;
        font-weight:800;
        text-align: center;
        span{
            /* background-image: linear-gradient(to right , #074C00,#42A341,#B8E2A3);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent; */
            color:#009f81;
        }
      }
    }
    /* industries headign end here */
    /* industries para start here */
    .industries_para{
        width: 50%;
        margin:1rem auto ;
        text-align: center;
        span{
            color: var(--para-color);
            font-weight: 500;
            font-size: 1.8rem;
            
            font-family: var(--font-family);
     
        }
    }
    /* industries para end here */
}
}
.industries_logo_image{
    display: grid;
    place-content: center;
    grid-template-columns: repeat(2,max-content);
    column-gap: 7rem;
    padding-top:10rem;
    padding-bottom:2rem;
 .industries_logo{
    display: grid;
    grid-template-columns: repeat(3,max-content);
    column-gap: 2rem;
    .industries_logo_list_two{
        margin-top:10rem;
    }
    .industries_logo_list_one,
    .industries_logo_list_two,
    .industries_logo_list_three{
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        padding-left:0;
        li{
            list-style: none;
            background-color: var(--icon-bg);
            box-shadow: var(--icon-box-shadow);
            width: 17rem;
            height: 18rem;
            display: grid;
            place-content: center;
            place-items: center;
            text-align: center;
            line-height: 1.5rem;
            border-radius:1rem;
            cursor: pointer;
              &:hover{
                background:var(--hover-lihear);
                color:#fff;
                .logo_one,
                .logo_two,
                .logo_three{
                i{
                     color:#fff;
                }
            }
              }
            .logo_one,
            .logo_two,
            .logo_three{
                i{
                    font-size:2.5rem;
                    color:var(--icon-color); 
                }
            }
            /* logo heading start here */
            .logo_one_heading,
            .logo_two_heading,
            .logo_three_heading{
                h4{
                    font-size: 1.5rem;
                    font-weight: 600;
                    line-height: 1.3;
                    text-align: center;
                    text-transform: uppercase;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                }
            }
            /* logo heading end here */
        }
           
    }
    .industries_logo_list_two{
            
            li{
                height: 17rem;
            }
           }
    .industries_logo_three{
       
            margin-top: -6rem;
          li{
            height: 20rem;
          }
    }
 }
 /* industries image start here */
 .industries_image{
    max-width: 44rem;
    min-width: 15rem;
    margin-top: 10rem;
    max-height:43rem;
    position:relative;
    z-index: 1;
    img{
        width: 100%;
        border-radius: 1rem;
        box-shadow: 2px 8px 17px 2px rgba(0,0,0,0.5);
    }
    &::after{
        content: "";
        position: absolute;
        background: #0070cc;
        width: 100%;
        height: 100%;
        top: -60px;
        right: -80px;
        z-index: -1;
        border-radius: 1rem;
    }
 }
 /* industries image end here */
}
/* media query start here */
@media (max-width:1150px){
    .industries_logo_image{
        grid-template-columns: repeat(1,max-content);
        place-items: center;
       .industries_logo{
        column-gap:1rem;
         .industries_logo_list_one,
         .industries_logo_list_two,
         .industries_logo_list_three {
            li{
                width:15rem;
            }
        }
        } 
        .industries_image{
            margin-top: 2rem;
        }
        .industries_image{
     max-width: 44rem;
     min-width: 15rem;
     max-height:43rem;
     margin-top: 11rem;
    &::after{
        content: "";
        position: absolute;
        background: #002037;
        width: 100%;
        height: 100%;
        top: -60px;
        right: 0;
        z-index: -1;
        border-radius: 1rem;
    }
 }
    }
}
/* media query end here */
@media (max-width:780px){
    .industries {
        .industries_heading_para{
            .industries_heading {
                h2{
                    font-size:2.5rem;
                }
            }
        }
    
    } 
}
@media (max-width:580px){
    .industries_logo_image{
       
       .industries_logo{
        column-gap: 0.5rem;
        .industries_logo_list_one,
         .industries_logo_list_two,
         .industries_logo_list_three  {
            li{
                width:14rem;
                .logo_one_heading,
                .logo_two_heading,
                .logo_three_heading {
                    h4{
                     font-size:1.2rem;
                    }
                }
            }
        }
        } 
        /* .industries_image{
            margin-top: 2rem;
        } */
    }
}
@media (max-width:360px){
    .industries_logo_image{
       .industries_image{
        max-width: 40rem;
        min-width: 10rem;
       }
       .industries_logo{
      
        .industries_logo_list_one,
         .industries_logo_list_two,
         .industries_logo_list_three  {
            li{
                width:13rem;
            }
        }
}
    }
}
`;

export default IndustriesStyle;
